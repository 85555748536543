import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Flex, Text, Input } from 'crox-new-uikit';
import ScrollArea from 'react-scrollbar'
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';

import useMediaQuery from "use-mediaquery";
import FormGroup from '@mui/material/FormGroup'; 
import FormControlLabel from '@mui/material/FormControlLabel'; 
import { Swiper, SwiperSlide } from "swiper/react";
import Countdown from 'react-countdown';
import SwiperCore, { Navigation, Pagination, Autoplay, Scrollbar, A11y } from "swiper";

import { useMemo } from 'react';

import {
  MantineReactTable,
  useMantineReactTable,
  type MRT_ColumnDef,
  MRT_GlobalFilterTextInput,
  MRT_ToggleFiltersButton,
} from 'mantine-react-table';
//import { Box, Button, Flex, Menu, Text, Title } from '@mantine/core';
import { Box, Menu, Title, MantineProvider , useMantineTheme, ActionIcon, ExpandMore } from '@mantine/core';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { IconUserCircle, IconSend } from '@tabler/icons-react';
import { IconPhoto, IconSettings, IconHeart, IconChevronDown } from '@tabler/icons-react';
import { teal, blue, red, green, pink, purple } from '@mui/material/colors';

import Card from './Card';
import ReactModal from 'react-modal'
import myEpicGame from '../../../utils/MyEpicGame.json';
import awesomeGame from '../../../utils/StakingToken.json';
import { BigNumber, ethers } from "ethers";
import {
    Chain,
    Address,
    useContractWrite,
    useNetwork,
    usePrepareContractWrite,
    useWaitForTransaction,
    useFeeData,
    useBalance,
    useAccount,
    useContractReads,
  } from "wagmi";

import FENIX_ABI from "../../../models/abi/LobbyFacet.json";  
import HELPER_ABI from "../../../models/abi/HelperToken.json"; 
import MINER_ABI from "../../../models/abi/MinerFacet.json"; 
import { fenixContract } from "../../../libraries/fenixContract";
import { helperContract } from "../../../libraries/helperContract";
import { minerContract } from "../../../libraries/minerContract";
import { NFTGAME_CONTRACT_ADDRESS, transformCharacterData } from '../../../utils/constants';
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import { Icon } from '@iconify/react';
//import { Button } from "../../../components/CommonComponents";
import toast from "react-hot-toast";
//Mock Data
//import { data } from '../../../models/makeData';
import styled from "styled-components";
import ReactiveButton from 'reactive-button';
let isConfirm = false


SwiperCore.use([Navigation, Autoplay]);
function StatCard({ label, separator, value, percentage, amount, unit }) {
  return (
      <FD_Wrapper_Card>
          <FD_Wrapper_Stats>
              <FD_Wrapper_StatLabel>{label}</FD_Wrapper_StatLabel>
              <FD_Wrapper_StatSeparator>{separator}</FD_Wrapper_StatSeparator>
              <FD_Wrapper_StatValue>
                  <span>{value}</span>
                  <span style={{ color: "rgba(194, 183, 177, 1)" }}>
                      {" "}
                      {percentage}{" "}
                  </span>
              </FD_Wrapper_StatValue>
          </FD_Wrapper_Stats>
          <FD_Wrapper_Amount>
              {amount.slice(0, -1)}
              <FD_Wrapper_ImportantValue
                  style={{
                      color:
                          amount.charAt(amount.length - 1) === "M"
                              ? "rgba(199, 185, 178, 1)"
                              : "rgba(217, 81, 28, 1)",
                  }}
              >
                  {amount.charAt(amount.length - 1)}
              </FD_Wrapper_ImportantValue>
          </FD_Wrapper_Amount>
      </FD_Wrapper_Card>
  );
}

const SelectCharacter = ({ userId, stakedDays, refID, bonusWallets, characterNFT, currentDay, lastDayEntered, refetchAssets, refetchLastDay}) => {
    const isSmallScreen = useMediaQuery("(max-width:768px)");
    const { chain } = useNetwork();
    const { address } = useAccount();
    const { data: feeData } = useFeeData({ formatUnits: "gwei", watch: false, cacheTime: 60_000 });
    const { data: fenixBalance } = useBalance({
      address: address,
      //token: fenixContract(chain).address,
      staleTime: 20_000,
    });
    const navigate = useNavigate();

    const globalTheme = useMantineTheme();

    const [cardNum, setCardNum] = useState(4)
    const [charactersX, setCharactersX] = useState([])
    const [lockedDays, setLockedDays] = useState([])
    const [gameContract, setGameContract] = useState(null);
    const [progress, setProgress] = useState("0%");

    const [Genesis, setGenesis] = useState([])
    const [_lockedDays, _setLockedDays] = useState([])
    const [BonusYielder, setBonusYielder] = useState([])
    
    const [Referrers, setReferrers] = useState([])
    const [data, setData] = useState([])
    const [expand, setExpand] = useState(null)
    const [currentRow, setCurrentRow] = useState(null);

    const [Controllers, setControllers] = useState([])
    const [xControllers, setXControllers] = useState([])
    const [dividends, setDividends] = useState([])
    const [dayMiners, SetDayMiners] = useState([])
    const [tableUpdate, setTableUpdate] = useState(true)

    const [_disable, setDisable] = useState(false)
    const [_inDay, setInDay] = useState(false)
    const [_disableField, setDisableField] = useState(false)

    const [toggle, setToggle] = useState(false)  
    const [toggleSD, setToggleSD] = useState(false)  

    const [formInput, updateFormInput] = useState({ plsAmount: 1, referrerID: 0, address: address})
    const [renft, setRenft] = useState([])

    const [isNetworkSelectModalOpen, setIsNetworkSelectModalOpen] = useState(false);
    const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);

    const [lobbyType, setLobbyType] = useState(1);
    const [_waasLobby, setWaasLobby] = useState(1);
    const [lobbyWallet, setLobbyWallet] = useState(1);
    const [walletList, setWalletList] = useState([]);
    const [tokenTotals, setTokenTotals] = useState([]);
    const [todayDaily, setTodayDaily] = useState([]);
    const [lastDaily, setLastDaily] = useState([]);
    const [allDaily, setAllDaily] = useState([]);
    const [minerDays, SetMinerDays] = useState([]);
    const [globals, setGlobals] = useState([]);
    const [_daily, setDaily] = useState([]);
    const [yesDaily, setYesDaily] = useState([]);
    const [_minerDaily, setMinerDaily] = useState([]);
    const [pendingIDs, setPendingIDs] = useState([]);    
    const [lobbyMod, setLobbyMod] = useState([]);

    const [todayMiners, setTodayMiners] = useState(0);
    const [allMiners, setAllMiners] = useState(0);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });


  const theme = createTheme({
    palette: {
        navbar: blue[100],
        tag: {
            red: red[200],
            pink: pink[200],
            purple: purple[200],
            blue: blue[200],
            green: green[200],
        },
    },
    typography: {
        fontFamily: [
            "NotoSans",
            "NotoSansThai",
            "Arial",
            "Roboto",
            "'Helvetica Neue'",
            "sans-serif",
        ].join(","),
    },
    shape: {
        borderRadius: 15,
    },
});

    let formatter_6 = Intl.NumberFormat('en', { notation: 'compact', minimumFractionDigits: 6, maximumFractionDigits: 6 });
    let formatter_4 = Intl.NumberFormat('en', { notation: 'compact', minimumFractionDigits: 4, maximumFractionDigits: 4 });
    let formatter = Intl.NumberFormat('en', { notation: 'compact', minimumFractionDigits: 2, maximumFractionDigits: 2 });
    let formatter_1 = Intl.NumberFormat('en', { notation: 'compact', minimumFractionDigits: 0, maximumFractionDigits: 1 });


  //const currentDay = parseInt(readData?.[5]._hex, 16);


  /*** CONTRACT WRITE SETUP ***/
  
  const { config: lobbyEntryConfig, isLoading: lobbyEntryIsoading } = usePrepareContractWrite({
    address: fenixContract(chain).address,
    abi: FENIX_ABI.abi,
    chainId: chain?.id,
    functionName: "xfEnter",
    args: [(formInput.referrerID).toString(), (lobbyType).toString(), formInput.address, 0],
    overrides: {
      //value: ethers.utils.parseEther(String(formInput.plsAmount)),
      value: ethers.utils.parseEther(parseFloat(formInput.plsAmount) >= 1 ? String(Number(formInput.plsAmount)) : String(0)),
      gasLimit: 14900000,
    },
    //enabled: false,
  });

  const { data: lobbyEntryWriteData, isLoading: lobbyEntryWriteLoading, write: lobbyEntryWrite } = useContractWrite({
    ...lobbyEntryConfig,
    //onSuccess(_data) {
    //  reset;
    //  setProcessing(true);
    //  setDisabled(true);
    //},
  });


  const { isLoading: entryTxIsLoaging  } = useWaitForTransaction({
    hash: lobbyEntryWriteData?.hash,
    onSuccess(data) {
      toast.success("Your entry has been initiated. Happy earning!");
      refetchAssets?.();
      refetchLastDay?.();
      refetchGlobals?.();
      refetchDividends?.();
      refetchDailyData?.();
      refetchWaasLobby?.();   

      //router.push("/lobby/active");
    },
    onError(err) {
      toast.error("Initiating entry was unsuccessful. Please try again later.");
    },    
  });


  /*** CONTRACT WRITE SETUP ***/
  
  const { config: autoCompoundConfig, isLoading: autoCompoundIsoading } = usePrepareContractWrite({
    address: minerContract(chain).address,
    abi: MINER_ABI.abi,
    chainId: chain?.id,
    functionName: "setUserAutoCompound",
    args: [(userId).toString(), address, stakedDays === 7777 ? false : true],
    overrides: {
      //value: ethers.utils.parseEther(String(formInput.plsAmount)),
      //value: ethers.utils.parseEther(parseFloat(formInput.plsAmount) >= 1 ? String(Number(formInput.plsAmount)) : String(0)),
      gasLimit: 30000000,
    },
    //enabled: false,
  });

  const { data: autoCompoundWriteData, isLoading: autoCompoundWriteLoading, write: autoCompoundWrite } = useContractWrite({
    ...autoCompoundConfig,
    //onSuccess(_data) {
    //  reset;
    //  setProcessing(true);
    //  setDisabled(true);
    //},
  });


  const { isLoading: autoCompoundIsLoaging  } = useWaitForTransaction({
    hash: autoCompoundWriteData?.hash,
    onSuccess(data) {
      toast.success("Your entry has been initiated. Happy earning!");
      refetchAssets?.();
      //refetchLastDay?.();
      //refetchGlobals?.();
      //refetchDividends?.();
      //refetchDailyData?.();
      //refetchWaasLobby?.();   

      //router.push("/lobby/active");
    },
    onError(err) {
      toast.error("Initiating entry was unsuccessful. Please try again later.");
    },    
  });


  const arrayRange = (start, stop, step) =>
    Array.from(
    { length: (stop - start) / step + 1 },
    (value, index) => start + index * step
    );



  const switchHandler = (event) => {
    console.log("TEST");
    if(event.target.checked == true) setLobbyType(2);
    if(event.target.checked == false) setLobbyType(1);
  };


    
    
    //const handleLogin = () => {
    //    isConfirm = true
    //    localStorage.setItem("accountStatus", "1");
    //    return connect({ connector });
    //}

    //const handleLogout = () => {
    //    isConfirm = false
    //    localStorage.removeItem("accountStatus")
    //    //disconnect();
    //}

    function copyToClipBoard() {
        var x = document.getElementById("snackbar");
        x.className = "show";
        setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
    }

    function closeModal() {
        setIsNetworkSelectModalOpen(false);
        setIsSelectModalOpen(false);
    }



    function writeModal() {
        setIsNetworkSelectModalOpen(false);
        lobbyEntryWrite();
    }

    function writeSwitchModal() {
      setIsSelectModalOpen(false);
      autoCompoundWrite();
    }


    const customStyles = {
        overlay: {
            position: 'fixed',
            zIndex: 1000,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.9)'
        },        
        content: {
            position: 'absolute',
            top: '40%',
            left: '50%',
            transform: 'translate(-40%, -50%)',
            right: 'auto',
            bottom: 'auto',
            backgroundColor: "transparent",
            border: 'none',
            overflow: 'hidden',
            

            position: 'absolute',


            background: '#fff',

            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',




        },
      
    };

    ReactModal.defaultStyles.overlay.backgroundColor = 'rgba(0,0,0,0)';

    const isMobile = useMediaQuery("(max-width: 600px)")

    const one = useMediaQuery("(max-width: 830px)");
    const two = useMediaQuery("(max-width: 1050px)");
    const three = useMediaQuery("(max-width: 1280px)");
    const four = useMediaQuery("(max-width: 1500px)");
    const five = useMediaQuery("(max-width: 1700px)");

    useEffect(() => {
      //setData([]);
      setTableUpdate(false);
    })

    useEffect(() => {
      if (_daily?.length > 0 && lockedDays?.length > 0 && characterNFT?.[0]?.lockedDay > 0)
        navigate("/");
        //window.location.reload()
    }, [address, chain])


    useEffect(() => {
      if (one){
          //console.log("...1");
          setCardNum(1)
      }
      else if (two){
          //console.log("...2");
          setCardNum(2)
      }
      else if (three){
          //console.log("...3");
          setCardNum(3)
      }
      else if (four){
          //console.log("...4");
          setCardNum(4)
      }
      else if (five){
          //console.log("...5");
          setCardNum(5)
      } else {
          setCardNum(10)
      }
    }, [one, two, three, four, five])


    var { refetch:refetchDailyData } = useContractReads({
        contracts: lockedDays?.map((lobby) =>    
        ({
              ...fenixContract(chain),
              functionName: "dailyData",
              args: [lobby >= 0 ? lobby : 0],     
          })
        ),
        onSuccess(data) {
          if(data?.length > 0)
            setDaily(data);
      },        
        cacheTime: 300000,
        watch: false,
      });


      var { refetch:refetchDailyData } = useContractReads({
        contracts: _lockedDays?.map((lobby) =>    
        ({
              ...fenixContract(chain),
              functionName: "dailyData",
              args: [lobby >= 0 ? lobby : 0],     
          })
        ),
        onSuccess(data) {
          if(data?.length > 0)
            setMinerDaily(data);
      },        
        cacheTime: 300000,
        watch: false,
      });

      var { data: _waas, refetch:refetchWaasLobby } = useContractReads({
        contracts: lockedDays?.map((lobby) =>    
        ({
              ...fenixContract(chain),
              functionName: "_waasLobby",
              args: [lobby > 0 ? lobby : 1],    
          })
        ),
        cacheTime: 300000,
        watch: false,
      });



      var { refetch:refetchTotals } = useContractReads({
        contracts: [
          {
            ...helperContract(chain),
            functionName: "totalTokens",
          },     
        ],
        onSuccess(data) {
          console.log("...DATA TOTALs", data)

          
          
        },   
        cacheTime: 300000,     
        watch: false,
      });




      var { refetch:refetchGlobals } = useContractReads({
        contracts: [
          {
            ...fenixContract(chain),
            functionName: "_waasLobby",
            args: [currentDay > 0 ? currentDay : 1]
          },    
          {
            ...helperContract(chain),
            functionName: "totalTokens",
          },     
          {
            ...minerContract(chain),
            functionName: "CURRENT_BONUS_WALLET",
          },       
          {
            ...minerContract(chain),
            functionName: "_minerDays",
          },      
          {
            ...fenixContract(chain),
            functionName: "globals",
          },  
          {
            ...fenixContract(chain),
            functionName: "dailyData",
            args: [currentDay > 0 ? currentDay - 1 : 0]
          },   
          {
            ...fenixContract(chain),
            functionName: "pendingLobbyIDS",
          },  
          {
            ...fenixContract(chain),
            functionName: "dailyData",
            args: [currentDay >= 2 ? currentDay - 1 : 0]
          },                                                                                                                                                                                 
        ],
        onSuccess(data) {
          //console.log("...TOTALs", data)
          if(Number(data?.[0]) > 0)
            setWaasLobby(Number(data?.[0]) / (10 ** 18));
          if(data?.[1]?.length > 0)
            setTokenTotals(data?.[1]);
          if(Number(data?.[2]) > 0)
            setLobbyWallet(Number(data?.[2]));
          if(data?.[3]?.[1]?.length > 0)
            SetMinerDays((data?.[3]?.[1]).map(x => Number(x)))
          if(data?.[4]?.length > 0)
            setGlobals(data?.[4]);
          if(data?.[5]?.length > 0)
            setYesDaily(data?.[5]);
          if(data?.[6]?.length > 0)
            setPendingIDs(data?.[6]);          
          if(data?.[7]?.length > 0)
            setLobbyMod([((Number(data?.[7]?.lobbyMod) - Number(data?.[7]?.lobbyMod) % 100000000) / 10**9), (Number(data?.[7]?.lobbyMod) % 100000000)]);  
        },   
        cacheTime: 300000,     
        watch: false,
      });




      var { refetch:refetchDividends } = useContractReads({
        contracts: Controllers?.map((character) =>    
        ({
              ...minerContract(chain),
              functionName: "_calcPayoutDividendsReward",
              args: [character?.stakeShares, ((Number(character?.lockedDay) - (Number(character?.lockedDay) % 1000000000)) / 1000000000 ), currentDay],    
          })
        ),
        onSuccess(data) {
          //if (!isNaN(parseInt(data?.[0]?._hex, 16)))
          //if(dividends.length === 0)
          if(data?.length > 0)
            setDividends(data);
          //character = {...character, dividend: parseInt(data?.[0]?._hex, 16) /  10 ** 18}
          //Object.assign(character, { dividend: parseInt(data?.[0]?._hex, 16) /  10 ** 18} )
          //Object.assign(character, { xfactor: (parseInt(data?.[0]?._hex, 16) / (parseInt(character?.rawAmount?._hex, 16))) } )
        },  
        cacheTime: 300000,
        watch: false,
      });      



      var { refetch:refetchDayMiners } = useContractReads({
        contracts: Controllers?.map((character) =>    
        (
          {
            ...minerContract(chain),
            functionName: "_dayMiners",
            args: [minerDays?.indexOf(Number(character?.lockedDay))]
          }
          )
        ),
        onSuccess(data) {
          //if (!isNaN(parseInt(data?.[0]?._hex, 16)))
          if(data?.length > 0)  
            SetDayMiners(data);
          //character = {...character, dividend: parseInt(data?.[0]?._hex, 16) /  10 ** 18}
          //Object.assign(character, { dividend: parseInt(data?.[0]?._hex, 16) /  10 ** 18} )
          //Object.assign(character, { xfactor: (parseInt(data?.[0]?._hex, 16) / (parseInt(character?.rawAmount?._hex, 16))) } )
        },  
        cacheTime: 300000,
        watch: false,
      });  



      var { refetch: refetchDailyData } = useContractReads({
        contracts: arrayRange(
          //((isNaN(lockedDays?.[0]) ? 1 : lockedDays?.[0]) - 1) ?? 1,
          1,
          Number(currentDay),
          1,
        )?.map((lobby) => ({
          ...fenixContract(chain),
          functionName: "dailyData",
          args: [lobby >= 0 ? lobby : 0],
        })),
        onSuccess(data) {
          if (data?.length > 0) setAllDaily(data);
        },
        cacheTime: 300000,
        watch: false,
      });


      //console.log("Lobby Contract", fenixContract(chain));
      //console.log("Helper Contract", helperContract(chain));
      //console.log("Miner Contract", minerContract(chain));
      //console.log("walletList", lobbyWallet, walletList);
      //console.log("bonusWallets", bonusWallets, bonusWallets?.[minerDays.indexOf(2)]?.rawAmount);
      //data?.[3]?.[1].indexOf(BigNumber.from(currentDay))
      //console.log("SelectCharacters");
      
      console.log('...minerDays', minerDays, minerDays.indexOf())
      //minerDays, minerDays.indexOf(parseInt(String(2), 16)), 
      console.log('characterNFT:', characterNFT)
      console.log("_daily", _daily?.length, Number(_daily?.[currentDay-1]?.lobbyPulse), _daily);
      console.log("_minerDaily", _daily?.length, Number(_daily?.[currentDay-1]?.lobbyPulse), _daily);
      console.log("_lockedDays", _lockedDays);
      console.log("lockedDays", lockedDays);
      
      //console.log("lastDayEntered", lastDayEntered);
      //console.log("arrayRange", arrayRange(lockedDays?.[0], lockedDays?.[lockedDays?.length - 1], 1));
      //console.log("allDaily", Number(allDaily?.[currentDay-1]?.lobbyPulse), allDaily);
      //console.log("BonusYielder", BonusYielder);
      //console.log("Controllers...", Controllers);
      //console.log("Genesis...", Genesis);
      console.log("xControllers...", xControllers);
      
      console.log("stakedDays...", stakedDays);
      //console.log("Data...", data);
      //console.log("Dividends", dividends);
      console.log("_DayMiners", dayMiners);
      console.log("globals", globals);
      console.log("address", address);
      console.log("pendingIDs", pendingIDs);      
      //console.log("endDate", endDate);
      console.log("yesDaily", yesDaily);
      //console.log("tokenTotals", tokenTotals);
      //console.log("currentDay, lastDayEntered", currentDay, lastDayEntered?.[0]?.day, _inDay);
    

    /*
    useEffect(() => {
        if (characterNFT.length > 0) {
            const characters = characterNFT.map((characterData) => transformCharacterData(characterData))
            //console.log('characters $$$$$', characters)
            setCharacters(characters)
        }

        return () => {
            if (gameContract) {
                gameContract.off('CharacterNFTMinted', onCharacterMint)
            }
        }
    }, [characterNFT])
    */

    

    useEffect(() => {
      if (
        _daily?.length > 0 &&
        _minerDaily?.length > 0 &&
        lockedDays?.length > 0 &&
        characterNFT?.[0]?.lockedDay > 0
      ) {
        //if (lockedDays?.length > 0 && characterNFT?.[0]?.lockedDay > 0) {
  
        //console.log("HERE HERE HERE");
        let chars = [];
        let gChars = [];
        let cChars = [];
        let controllerChars = [];
        let iChars = [];
        let bChars = [];
        let pos = 0;
        let prevGroup = 0;
        let _todayMiners = 0;
        let _allMiners = 0;

        let _prevDayDivs = 0;
        for (var i = 0; i < _lockedDays.length; i++) {
          let _day =
            (Number(_lockedDays[i]) - (Number(_lockedDays[i]) % 1000000000)) / 1000000000;
          let _cChars = [];
  
          let _todayDaily = _daily?.filter((dal) => dal?._day === currentDay - 1);
          if (_todayDaily?.length > 0) setTodayDaily(_todayDaily);
  
          let _lastDaily = [];
          _lastDaily = allDaily?.filter((dal) => dal?._day === _day - 1);
          //console.log("_lastDaily", _lockedDays[i], _lastDaily);
          //if(_lastDaily?.length === 0 ) _lastDaily.push(yesDaily);;
          //else setTodayDaily(yesDaily);
  
          let daily = _daily?.filter((dal) => dal?._day === _day);
          let minerDaily = _minerDaily?.filter(
            (dal) => dal?._day === _lockedDays[i],
          );
  
          let cDaily = [];
  
          //if(lockedDays[i] === 1)
          cDaily = _daily?.filter((dal) => dal?._day === _day);
          //else
          //cDaily = _daily?.filter((dal) => dal?._day === lockedDays[i - 1]);
  
          let gCard = characterNFT.filter(
            (char) =>
              (Number(char?.lockedDay) - (Number(char?.lockedDay) % 1000000000)) /
                1000000000 ===
                lockedDays[i] && char.entryType === 0,
          );
  
          let byCard = bonusWallets.filter(
            (char) =>
              Number(char.entryId) === Number(lobbyWallet) &&
              char.levels === "BonusYielder" &&
              char.entryType === 4,
          );

          let _cCard = characterNFT.filter(
            (char) =>
              Number(char?.lockedDay) === _lockedDays[i] &&
              char.levels === "Controllers" &&
              (char.entryType === 3 || char.entryType === 0) &&
              char.status !== 10
          );
  
          if (daily?.[0]?._day > 0 && byCard[0]?.lockedDay > 0) {
            //console.log("...dailyBY", lockedDays[i], byCard[0], daily[0]);
            let chrs = {
              ...daily?.[0],
              ...byCard?.[0],
            };
            if (bChars?.length === 0) bChars.push(chrs);
          } else if (byCard?.[0]?.levels === "BonusYielder") {
            if (bChars?.length === 0) bChars.push(byCard?.[0]);
          }
  
          if (yesDaily?._day > 0 && gCard?.[0]?.lockedDay > 0) {
            //console.log("...dailyG", lockedDays[i], gCard[0], daily[0]);
            let chrs = {
              ...yesDaily,
              ...gCard?.[0],
              _dayP2XPool: _waas?.[i],
            };
            gChars.push(chrs);
          } else if (gCard?.[0]?.entryType === 0 && gCard?.[0]?.lockedDay > 0) {
            gChars.push(gCard?.[0]);
            //console.log("...dailyG2", lockedDays?.[i], gCard, daily?.[0]);
          }
          //console.log("...The Day", _day, _cCard[0]?.lockedDay, Number(characterNFT?.lockedDay), _lockedDays[i], characterNFT.levels, characterNFT.entryType);
          if (
            cDaily?.[0]?._day > 0 &&
            (_lastDaily?.[0]?._day > 0 || _day === 1) &&
            _cCard[0]?.lockedDay > 0
          ) {
            console.log("...In Day", _day);
            //console.log("...minerDaily?.[i]", minerDaily?.[0]);
            //let _prevDayDivs = 0;
            //if(prevGroup != Number(_cCard?.[0]?.lockedDay)) {
            for (var j = 0; j < _cCard.length; j++) {
              let chrs = {
                ...cDaily?.[0],
                ..._cCard?.[j],
                pos: pos,
                _dayP2XPool: _waas?.[i],
                headIndex: _cCard?.[j]?.groupId,
                tailIndex: _cCard?.[_cCard.length - 1]?.groupId,
              };
              _cChars.push({
                ...chrs,
                _oneFiveX: minerDaily?.[0]?.oneFiveX,
                prevDayDivs: _lastDaily?.[0]?.dayDividends,
                _lockedDay: Number(chrs?.lockedDay),
                lockedDay:
                  (Number(chrs?.lockedDay) - (Number(chrs?.lockedDay) % 1000000000)) /
                  1000000000,
              });
              cChars.push(chrs);
              //if (prevDay != ((Number(chrs?.lockedDay) - (Number(chrs?.lockedDay) % 100000)) / 100000 ))
              pos += 1;



              if(((Number(_cCard?.[j]?.lockedDay) - (Number(_cCard?.[j]?.lockedDay) % 1000000000)) / 1000000000 ) === currentDay)
                _todayMiners += (Number(_cCard?.[j]?.minersMod) % 100);
              _allMiners += (Number(_cCard?.[j]?.minersMod) % 100);

              //_prevDayDivs = _cCard?.[j].dayDividends;
            }
            //}
            //prevGroup = Number(_cCard?.[0]?.lockedDay);
          } else if (
            _cCard?.[0]?.entryType === 3 &&
            (_lastDaily?.[0]?._day > 0 || _day === 1)
          ) {
            console.log("...In Day", _day);
            //let _prevDayDivs = 0;
            //if(prevGroup != Number(_cCard?.[0]?.lockedDay)) {
            for (var j = 0; j < _cCard.length; j++) {
              let chrs = {
                ..._cCard?.[j],
                pos: pos,
                headIndex: _cCard?.[j]?.groupId,
                tailIndex: _cCard?.[_cCard.length - 1]?.groupId,
              };
              _cChars.push({
                ...chrs,
                _oneFiveX: minerDaily?.[0]?.oneFiveX,
                prevDayDivs: _lastDaily?.[0]?.dayDividends,
                _lockedDay: Number(chrs?.lockedDay),
                lockedDay:
                  (Number(chrs?.lockedDay) - (Number(chrs?.lockedDay) % 1000000000)) /
                  1000000000,
              });
              //if (prevDay != ((Number(chrs?.lockedDay) - (Number(chrs?.lockedDay) % 100000)) / 100000 ))
              pos += 1;
              //_prevDayDivs = _cCard?.[j].dayDividends;

               if(((Number(_cCard?.[j]?.lockedDay) - (Number(_cCard?.[j]?.lockedDay) % 1000000000)) / 1000000000 ) === currentDay)
                  _todayMiners += (Number(_cCard?.[j]?.minersMod) % 100);
                _allMiners += (Number(_cCard?.[j]?.minersMod) % 100);

            }
            //}
            //prevGroup = Number(_cCard?.[0]?.lockedDay);
          }
  
          if (_cChars?.length > 0) controllerChars.push(_cChars);
          //console.log("_lastDaily", _lastDaily);
          //_prevDayDivs = _cChars?.[0]?.dayDividends;
        }
  



        setTodayMiners(_todayMiners);
        setAllMiners(_allMiners);


        //console.log("chars", chars);
        if (gChars?.length > 0)
          setGenesis(gChars.filter((character) => character?.entryType === 0));
        if (bChars?.length > 0)
          setBonusYielder(
            bChars.filter((character) => character?.levels === "BonusYielder"),
          );
        //const DuelistsCount =  Duelists.length;
        if (chars?.length > 0)
          setReferrers(
            chars.filter((character) => character?.levels === "Referrers"),
          );
        //const ReferrersCount = Referrers.length;
        if (cChars?.length > 0)
          setControllers(
            cChars.filter((character) => character?.levels === "Controllers"),
          );
        //if (xControllers.length === 0 || tableUpdate == true) {
          setXControllers(controllerChars);
          setData(cChars);
        //}
        //const ReferrersCount = Controllers.length;
        //console.log("controllerChars", controllerChars);
      }
    }, [
      lockedDays,
      _daily,
      yesDaily,
      _minerDaily,
      allDaily,
      characterNFT,
      walletList,
      tableUpdate,
    ]);
  
    useEffect(() => {
      let cChars = [];
  
      //console.log("LENGTH", xControllers);
  
      //if(xControllers?.[xControllers.length - 1]?.[0]?.dividend > 0 && xControllers?.[0]?.[0]?.numItems > 0){
      for (var j = 0; j < xControllers.length; j++) {
        const array = xControllers?.[j];
        let divsTotal = 0;
        let entriesTotal = 0;
        let groupEntries = 0;
  
        const divs = Array.from(
          xControllers?.[j]?.map((item) => Number(item.dividend)),
        );
        const entries = Array.from(
          xControllers?.[j]?.map((item) => Number(item.rawAmount)),
        );
        const grpTol = Array.from(
          xControllers?.[j]?.map((item) => Number(item.numItems)),
        );
  
        divsTotal = divs.reduce(function (a, b) {
          return a + b;
        }, 0);
        //console.log("divsTotal", divs, divsTotal);
  
        entriesTotal = entries.reduce(function (a, b) {
          return a + b;
        }, 0);
        //console.log("entriesTotal", entries, entriesTotal);
  
        groupEntries = grpTol.reduce(function (a, b) {
          return a + b;
        }, 0);
  
        cChars.push({
          ...xControllers?.[j]?.[0],
          _dividend: divsTotal,
          _rawAmount: entriesTotal,
          groupEntries: groupEntries,
        });
      }
      if (cChars?.length > 0) {
        setData(cChars);
        setTableUpdate(false);
      }
      //const newData = [...cChars, ...data];
      //setData(newData);
      //}
    }, [xControllers, dividends, dayMiners, tableUpdate]);
  
    useEffect(() => {
      if (characterNFT.length > 0) {
        const lockedDays_ = Array.from(
          characterNFT?.map(
            (item) =>
              (Number(item.lockedDay) - (Number(item.lockedDay) % 1000000000)) /
              1000000000,
          ),
        );
        const _lockedDays = Array.from(
          characterNFT?.map((item) => Number(item.lockedDay)),
        );
        //if (Number(_lockedDays?.[0]) >= 1) {
  
        setLockedDays(
          [...new Set(lockedDays_)].sort(function (a, b) {
            return a - b;
          }),
        );
        _setLockedDays(
          [...new Set(_lockedDays)].sort(function (a, b) {
            return a - b;
          }),
        );
        //}
        //console.log('_lockedDays:', _lockedDays)
        //console.log('....Initiators:', Initiators)
      }
    }, [characterNFT]);
  
    useEffect(() => {
      if (bonusWallets.length > 0 && Number(lobbyWallet) > 0) {
        let BW = [];
        let LOCAL_BONUS_WALLET = Number(lobbyWallet);
        let walletCount = 5;
        do {
          //console.log("LOCAL_BONUS_WALLET", LOCAL_BONUS_WALLET);
          BW.push(LOCAL_BONUS_WALLET);
          //LOCAL_BONUS_WALLET = LOCAL_BONUS_WALLET > 1 ? LOCAL_BONUS_WALLET -= 1 : 10;
          LOCAL_BONUS_WALLET > 1
            ? (LOCAL_BONUS_WALLET -= 1)
            : (LOCAL_BONUS_WALLET = 10);
          walletCount -= 1;
        } while (walletCount > 0);
        setWalletList(BW);
      }
    }, [bonusWallets, lobbyWallet]);
  




  
    const columns = useMemo(
        () => [
          {
            id: 'employee', //id used to define `group` column
            header: 'All Entries Info',
            columns: [
              {
                accessorFn: (row) => {
                  //convert to Date for sorting and filtering
                  const sDay = new Date(row.startTs * 1000);
                  //sDay.setHours(0, 0, 0, 0); // remove time from date (useful if filter by equals exact date)
                  return sDay;
                },
                id: 'startDate',
                header: 'Start Date',
                //filterVariant: 'date-range',
                sortingFn: 'datetime',
                //enableColumnFilterModes: false, //keep this as only date-range filter with between inclusive filterFn
                Cell: ({ cell }) => cell.getValue().toLocaleDateString(), //render Date as a string
                Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
              },          
              {
                accessorFn: (row) => `${Number(row.lockedDay)}`, //accessorFn used to join multiple data into a single cell
                id: 'day', //id is still required when using accessorFn instead of accessorKey
                header: 'Day',
                size: 50,
                //filterVariant: 'autocomplete',
                Cell: ({ renderedCellValue, row }) => (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '16px',
                    }}
                  >
    
                    <span>{renderedCellValue}</span>
                  </Box>
                ),
              },  
              {
                accessorFn: (row) => `${formatter_4.format(Number(row?.prevDayDivs)  / 10 ** 18)} → ( ${row?.mines ?? 1} )`,
                id: 'dayentries', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                enableClickToCopy: true,
                header: 'Entries | Mines',
                size: 100,
              },                          
              {
                accessorFn: (row) => `${formatter_4.format(Number(row.rawAmount) <= Number(row.auctionPulse) ? Number(row.rawAmount / 10 ** 18) * ((row.minersMod % 100) ?? 1) : 0)} → ( ${(row.minersMod % 100) ?? 1} )`,
                id: 'plsentries', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                enableClickToCopy: true,
                header: 'Your Miners',
                size: 100,
              },    
              {
                accessorFn: (row, index) => {
                  //return parseInt(dividends?.[index]?._hex, 16) /  10 ** 18;
                  if (Number(row?._oneFiveX) > 0 && Number(row?._oneFiveX) != 20 && Number(currentDay) - Number(row.unlockedDay)  <= 5)
                    return formatter_4.format(((((5-walletList?.indexOf(Number(row?._oneFiveX)))/5) * Number(bonusWallets?.[Number(row?._oneFiveX)]?.stakedSuns)  / (10**18)) * ((Number(row?.rawAmount) * (Number(row?.minersMod) % 100)) / (Number(row?.minerPulse) )))  ?? 1 );
                  else return 0;
                  return Number(row?._oneFiveX);

                },                
                id: 'onfivebonus', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                enableClickToCopy: true,
                header: '1.5X Bonus',
                size: 100,
              },                
            ],
          },
          {
            id: 'id',
            header: 'Miner Info',
            columns: [
              {
                accessorFn: (row, index) => {
                  if (Number(row?.entryId)  < 21) {
                    //return formatter.format(parseInt(row?.stakeShares?._hex, 16) / (10 ** 18) ?? 0)
                    return formatter_4.format((Number(row.dayDividends) * 0.8 / 5 / (10 ** 18)) ?? 0);
                  } else {
                    if(Number(row?.dayDividends) === 0) return 0.000000
                    else if(row.status == 7) return formatter_4.format(Number(row.rawAmount) <= Number(row.dayDividends) ? Number(row.rawAmount / 10 ** 18) * ((row.minersMod % 100) ?? 1) * 2: 0)
                    //else return formatter_4.format((Number(dividends[row.id]) * row.groupEntries ?? 1 )?? 0);
                    else return formatter_4.format(((Number(dividends?.[row.pos]) / (10 ** 18)) * (row.minersMod % 100) ?? 1 )?? 0);
                  }
                },
                id: 'dividends', //hey a simple column for once
                header: 'Dividends',
                //filterVariant: 'multi-select',
                size: 100,
              },              
              {
                accessorFn: (row, index) => {
                  if(Number(row?.dayDividends) === 0) return 0.000000
                  else if(row.status == 7) return 2.00
                  else return formatter_4.format(((Number(dividends?.[row.pos]) / Number(row.rawAmount)) ) ?? 0);
                  //return formatter.format(Number(row.dividend / (10 ** 18)) ?? 0 / (parseInt(row.rawAmount?._hex, 16) / (10 ** 18))  ?? 0);
                },

                //accessorFn: (row) => `${formatter.format(Number(row.xfactor))}`,
                id: 'xfactor', //hey a simple column for once
                header: 'X-Factor',
                //filterVariant: 'multi-select',
                size: 100,
              },
              {
                accessorFn: (row, index) => {
                  const _status = ['QUEUED','ENTER','COLLECT','REFERRED','ACTIVE', '1.5X+','1.5x PAST', '2X', 'COMPLETED','REFERRAL','ENDED'];
                  if(Number(row?.lockedDay) == 1) return _status[7]
                  if(row.status === 5 && Number(currentDay) - Number(row.unlockedDay) >= 5) return _status[6]
                  if((Number(dividends?.[index]) / Number(row.rawAmount)) > 0 && Number(row.dayDividends) > 0) return _status[row.status];
                  else return _status[0];
                },
                id: 'status',
                header: 'Status',
                //filterVariant: 'date-range',
                sortingFn: 'datetime',
                //enableColumnFilterModes: false, //keep this as only date-range filter with between inclusive filterFn
                Cell: ({ cell, row }) => (
                  <>
                  
                  <Box
                    sx={(theme) => ({
                      backgroundColor:
                        cell.getValue() == "ACTIVE" || cell.getValue() == "1.5x PAST"
                          ? theme.colors.blue[9]
                          : cell.getValue() == "1.5X+"
                          ? theme.colors.yellow[9]
                          : cell.getValue() == "2X"
                          ? theme.colors.green[9]                          
                          : theme.colors.pink[9],
                      borderRadius: '4px',
                      color: '#fff',
                      maxWidth: '9ch',
                      padding: '4px',
                    })}
                  >
                    {cell.getValue()?.toLocaleString?.('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}


                  </Box>
                  { xControllers?.[row.id]?.length > 1 ? (
                  <Flex mt="5px">
                    <Text fontSize="14px" bold>1 </Text>
                    <Text fontSize="14px" mr="3px" bold style={{lineHeight: 1}}>st</Text>
                    <Text fontSize="14px" mr="3px" bold>of </Text>
                    <Text fontSize="14px" ml="3px" bold>{xControllers?.[row.id]?.length}</Text>
                  </Flex>) : (<></>)}

                  
                  </>
                ),
                Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
              },          
            ],
          },
        ],
        [data],
      );
    
    
     
      const table = useMantineReactTable({
        columns,
        data: data, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
        initialState: { pagination: { pageSize: 25 } },



        
        //enableColumnFilterModes: true,
        //enableColumnOrdering: true,
        enableFacetedValues: true,
        //enableGrouping: true,
        //enablePinning: true,
        enableRowActions: true,
        enableRowSelection: true,
        paginationDisplayMode: 'pages',
        positionToolbarAlertBanner: 'bottom',
              
        mantinePaperProps: {
          //style: { 'border': '1px solid #40C057' },
          style: {
            border: "0px solid #40C057",
            marginTop: "24px",
            backgroundColor: "transparent",
            borderRadius: "14px",
        },
        },        

        mantineTableProps: {
          striped: true,
        },

                                      
                                            mantineTableBodyRowProps: {
                                              style: { 'border': '5px solid #212121'},
                                            },
                                            mantineTableBodyCellProps: {
                                              //easier way to create media queries, no useMediaQuery hook needed.
                                              style: {
                                                fontSize: '16px',
                                                fontWeight: '700',
                                                '@media (minWidth: 600px)': {
                                                  fontSize: '12px',
                                                },
                                              },
                                            },
                                            mantineTableHeadCellProps: {
                                              //easier way to create media queries, no useMediaQuery hook needed.
                                              style: {
                                                fontSize: '18px',
                                                fontWeight: '700',
                                                '@media (minWidth: 600px)': {
                                                  fontSize: '12px',
                                                },
                                              },
                                            },                                            
        mantinePaginationProps: {
          radius: "xl",
          size: "lg",
          position: "center",
          style: {
              backgroundColor: "#221f1d", // Change the background color of the table
              zIndex: '99',
          },
          classNames: {
              root: "pagination-container",
              item: "pagination-button",
              select: "pagination-select",
          },
        },



        mantineExpandButtonProps: ({ row }) => ({
          children: 
          <>
            <Box 
              sx={(theme) => ({
                backgroundColor: 
                row.original.status == 4
                ? theme.colors.blue[9]
                : row.original.status == 5 && (Number(currentDay) - Number(row.original.unlockedDay)) >= 5
                ? theme.colors.blue[9]
                : row.original.status == 5
                ? theme.colors.yellow[9]
                : row.original.status == 7
                ? theme.colors.green[9]                          
                : theme.colors.pink[9],
                borderRadius: '4px',
                color: '#fff',
                //width: '100px',
                //padding: '4px',
                flex:1                
              })}
            >
            <ActionIcon >
              <IconChevronDown />
            </ActionIcon>
            </Box>

            </>
        }),  
        
        //mantineSearchTextInputProps: {
        //  placeholder: 'Search Employees',
        //},
        mantineExpandButtonProps: ({ row, table }) => ({
          onClick: () => (setExpand(row.id), table.setExpanded({ [row.id]: !row.getIsExpanded() })), //set only this row to be expanded
        }),

        
        renderDetailPanel: ({ row }) => (
          (row.id === expand ? 
         <Box
            key={row.id}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'left',
              gap: '16px',
              padding: '16px',
            }}
          >
    


            {xControllers?.[row.id]?.map((character, index) => (
              
              <Flex key={index}>
                  <Card 
                  refID={refID}
                  character={character} 
                  indexX={index} 
                  row={row.id} 
                  levels={'Controllers'} 
                  gameContract={gameContract} 
                  currentDay={currentDay} 
                  lastDayEntered={lastDayEntered} 
                  walletList={walletList} 
                  bonusWallets={bonusWallets} 
                  minerDays={minerDays} 
                  dividend={dividends?.[character?.pos]} 
                  dividends={dividends} 
                  minerCounts={xControllers?.[character?.pos]?.map(x => x.numItems)} 
                  refetchAssets={refetchAssets} 
                  refetchLastDay={refetchLastDay}
                  refetchGlobals={refetchGlobals}
                  refetchDividends={refetchDividends}
                  refetchDailyData={refetchDailyData}
                  refetchWaasLobby={refetchWaasLobby}
                  setTableUpdate={setTableUpdate}

                  />
              </Flex>
            ))}




          </Box> : null)
        ),
        renderRowActionMenuItems: () => (
          <>
            <Menu.Item>View Profile</Menu.Item>
            <Menu.Item>Send Email</Menu.Item>
          </>
        ),
        renderTopToolbar: ({ table }) => {
          const handleDeactivate = () => {
            table.getSelectedRowModel().flatRows.map((row) => {
              alert('deactivating ' + row.getValue('name'));
            });
          };
    
          const handleActivate = () => {
            table.getSelectedRowModel().flatRows.map((row) => {
              alert('activating ' + row.getValue('name'));
            });
          };
    
          const handleContact = () => {
            table.getSelectedRowModel().flatRows.map((row) => {
              alert('contact ' + row.getValue('name'));
            });
          };
    
          {/*return (
            <Flex p="md" justify="space-between">
              <Flex gap="xs">
                import MRT sub-components
                <MRT_GlobalFilterTextInput table={table} />
                <MRT_ToggleFiltersButton table={table} />
              </Flex>
              <Flex sx={{ gap: '8px' }}>
                <Button
                  color="red"
                  disabled={!table.getIsSomeRowsSelected()}
                  onClick={handleDeactivate}
                  variant="filled"
                >
                  Deactivate
                </Button>
                <Button
                  color="green"
                  disabled={!table.getIsSomeRowsSelected()}
                  onClick={handleActivate}
                  variant="filled"
                >
                  Activate
                </Button>
                <Button
                  color="blue"
                  disabled={!table.getIsSomeRowsSelected()}
                  onClick={handleContact}
                  variant="filled"
                >
                  Contact
                </Button>
              </Flex>
            </Flex>
          );*/}
        },
      },[data]);
    




      const renderProgress = (status) => {
        const zeroPad = (num, places) => String(num).padStart(places, '0')
        switch (status) {
          case 1:
            return <div className="text-center uppercase">Ended</div>;
          case 2:
            return <div className="text-center uppercase">Deferred</div>;
          default:
            return (
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full rounded primary-background">
                    <div className="h-6 rounded progress-gradient" style={{ width: "100%" }} />
                  </div>
                </div>
                <div className="absolute inset-0 flex items-center">
                  <div className="h-6 rounded glass" style={{ width: progress }} />
                </div>
                  <div className="relative flex justify-center">
                {timeLeft.hours || timeLeft.minutes || timeLeft.seconds ? (
            <p>
              <span>{timeLeft.hours}</span>
              <span>{' Hrs : '}</span>
              <span>{timeLeft.minutes}</span>
              <span> {' Mins : '}</span>
              <span>{timeLeft.seconds}</span>
              <span>{' Secs '}</span>

            </p>
          ) : (
            <span>Time is up 🔥</span>
          )}
                </div>
              </div>
            );
        }
      };


      const timeUnits = [
        { value: "0", label: "Day" },
        { value: "12", label: "Hour" },
        { value: "25", label: "Min" },
        { value: "00", label: "Sec" },
    ];

    //var offset = new Date().getTimezoneOffset()
    //const year = new Date().getFullYear();
    var tomrw = new Date(+new Date().setHours(0, 0, 0,0)+ (86400000 * 2)).toLocaleDateString('fr-CA')
    //console.log("TOMRW", tomrw);
    const countdowntime =  +new Date(tomrw) - +new Date();;
    // Random component
const Completionist = () => <DividerIcon
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/fd478029cbe411d689676816713fb92971dbbb9da111e4d020922bc82454c908?apiKey=c2eace46523148b195c70f9101a6de88&"
        />;

// Renderer callback with condition
const renderer = ({ days,hours, minutes, seconds, completed }) => {
  const radius = 40; 
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return <> 
        {/*
        <TimeUnit>
            <TimeValue>
            {
                Array.from({ length: 30 }, (_, i) => {
                    // Calculate angle in radians
                    const angle = (i / 30) * 2 * Math.PI;
                    // Calculate position based on angle
                    const left = radius + radius * Math.cos(angle); // 150 is the center of the TimeUnit
                    const top = radius + radius * Math.sin(angle);
                    return (
                        <div
                            className={`circlebox days ${i === days ? "active" : ""}`}
                            style={{ left: `${left}px`, top: `${top}px` }}
                            key={i}
                        ></div>
                    );
                })
            }
            <div className="wrapper"> <div style={{fontSize:"30px",color:"#FF6E36",marginBottom:"-4px"}}> {days?days:0}</div>
            <TimeLabel>
                Days
            </TimeLabel></div>
           
            </TimeValue>
        </TimeUnit>
        */}
        <TimeUnit>
            <TimeValue>
            {
                Array.from({ length: 24 }, (_, i) => {
                    // Calculate angle in radians
                    const angle = (i / 24) * 2 * Math.PI;
                    // Calculate position based on angle
                    const left = radius + radius * Math.cos(angle); // 150 is the center of the TimeUnit
                    const top = radius + radius * Math.sin(angle);
                    return (
                        <div
                            className={`circlebox hours ${i === hours ? "active" : ""}`}
                            style={{ left: `${left}px`, top: `${top}px` }}
                            key={i}
                        ></div>
                    );
                })
            }
            <div className="wrapper"> <div> {hours < 10 ? "0" : ""}{hours ? hours : 0}</div>
            <TimeLabel>
                Hours
            </TimeLabel></div>
           
            </TimeValue>
        </TimeUnit>
        <TimeUnit>
            <TimeValue>
            {
                Array.from({ length: 30 }, (_, i) => {
                    // Calculate angle in radians
                    const angle = (i / 30) * 2 * Math.PI;
                    // Calculate position based on angle
                    const left = radius + radius * Math.cos(angle); // 150 is the center of the TimeUnit
                    const top = radius + radius * Math.sin(angle);
                    return (
                        <div
                            className={`circlebox minutes ${i ===  Math.ceil(minutes/2) ? "active" : ""}`}
                            style={{ left: `${left}px`, top: `${top}px` }}
                            key={i}
                        ></div>
                    );
                })
            }
            <div className="wrapper"> <div> {minutes < 10 ? "0" : ""}{minutes?minutes:0}</div>
            <TimeLabel>
                Mins
            </TimeLabel></div>
           
            </TimeValue>
        </TimeUnit>
        <TimeUnit>
            <TimeValue>
            {
                Array.from({ length: 30 }, (_, i) => {
                    // Calculate angle in radians
                    const angle = (i / 30) * 2 * Math.PI;
                    // Calculate position based on angle
                    const left = radius + radius * Math.cos(angle); // 150 is the center of the TimeUnit
                    const top = radius + radius * Math.sin(angle);
                    return (
                        <div
                            className={`circlebox seconds ${i === Math.ceil(seconds/2) ? "active" : ""}`}
                            style={{ left: `${left}px`, top: `${top}px` }}
                            key={i}
                        ></div>
                    );
                })
            }
            <div className="wrapper"> <div> {seconds < 10 ? "0" : ""}{seconds?seconds:0}</div>
            <TimeLabel>
                Sec
            </TimeLabel></div>
           
            </TimeValue>
        </TimeUnit>
    </>;
  }
};
  return (

    <ScrollArea
    speed={1}
    smoothScrolling
    verticalContainerStyle={{ borderRadius: "10px" }}
    verticalScrollbarStyle={{ borderRadius: "10px" }}
      >
          {currentDay > 0 && (

<Flex flexDirection="column">
                    <Flex flexDirection="column" mt="60px" mb="36px">

          <DashInfoHeading>
                            <Flex flexDirection="row" mb="40px">
                                <Text
                                    fontSize="36px"
                                    color="#fff"
                                    bold
                                    mr="14px"
                                >
                                    Dashboard
                                </Text>
                                <Text fontSize="36px" color="#FF6E36">
                                    P2X
                                </Text>
                            </Flex>
                        </DashInfoHeading>



                        <Flex
                            alignItems="left"
                            justifyContent="space-between"
                            mb="20"
                        >
                            <DashInfoWrapper>
                                <BoxLeft>
                                    <InfoBoxWrapper>
                                        <Flex
                                            flexDirection={
                                                isSmallScreen ? "column" : "row"
                                            }
                                            justifyContent="space-between"
                                        >
                                            <InfoBox>
                                                <ContentInfo>
                                                  {Number(
                                                      (tokenTotals?.miners ?? 0) % 100000000000
                                                  )}
                                                  <Flex flexDirection='column' justifyContent="space-between" alignItems='baseline'>
                                                      <HeadingInfo>
                                                          <Flex flexDirection="row">
                                                              <Text>Total User Miners (</Text>
                                                              <Text color="white">Cards: {(((tokenTotals?.miners ?? 0) - (tokenTotals?.miners ?? 0) % 100) / 100000000000).toFixed(0)}</Text>
                                                              <Text>)</Text>
                                                          </Flex>
                                                      </HeadingInfo>
                                                      <Flex flexDirection='row' mt="3px">
                                                          <Text ml="5px" mr="1px" color="yellow" >({"Your Miners "}</Text>
                                                          <Text ml="5px" ml="8px" color="white" > {allMiners+" | Today: "+todayMiners} )</Text>
                                                      </Flex>
                                                  </Flex>
                                                </ContentInfo>
                                              </InfoBox>

                                            <InfoBox>
                                                <Flex flexDirection='row' justifyContent="space-between" alignItems='baseline'>
                                                    <ContentInfo alignItems='end'>
                                                        {/* {Number(
                                                        todayDaily?.[0]
                                                            ?.auctionPulse
                                                    )
                                                        ? Number(
                                                            tokenTotals?.lobbies
                                                        )
                                                        : 0} */}
                                                        {formatter.format(Number(
                                                        (globals?.totalEntries ?? 0) / (10 ** 18) ??
                                                            0
                                                        ))}
                                                    </ContentInfo>
                                                </Flex>  
                                                <Flex flexDirection='column' justifyContent="space-between" alignItems='baseline'>
                                                <HeadingInfo>
                                                        
                                                        <Flex flexDirection='row' mt="3px">
                                                        <Text>Lobby Totals</Text>
                                                        <Text ml="5px"  color="yellow" >({"Today"}</Text>     
                                                        <Text ml="3px"  color="white" > {formatter.format((Number(allDaily?.[currentDay-1]?.lobbyPulse ?? 0) / 10 ** 18))}</Text>
                                                        
                                                        <Text ml="5px"  color="white" >| {lobbyMod[1] > 0 || pendingIDs[0]?.length > 0 ? lobbyMod[1] + pendingIDs[0]?.length - lobbyMod[0] : 0}</Text>)                                           
                                                    </Flex>                                                         
                                                    </HeadingInfo>

                                                    <Flex flexDirection='column' mt="3px">
                                                    <Flex flexDirection='row' mt="3px">
                                                        <Text ml="0px"  color="white" >Your</Text> 
                                                        <Text ml="5px"  color="orange" >{"Auctions:"}</Text> 
                                                        <Text ml="5px"  color="white" > {(Number((Genesis?.[0]?.minersMod ?? 0) % 1000 - Number((Genesis?.[0]?.minersMod ?? 0) % 10))) / 100}</Text>
                                                        <Text ml="5px"  color="orange" >{"Lobbys:"}</Text>    
                                                        <Text ml="5px"  color="white" >{Number((Genesis?.[0]?.minersMod ?? 0) % 10)}</Text> 
                                                    </Flex>       
  
                                                    </Flex>                                              
                                                </Flex>  
                                            </InfoBox>
                                            <InfoBox>
                                                <ContentInfo>
                                                {formatter.format(Number(globals?.nextStakeSharesTotal ?? 0) / (10 ** 18))}
                                                </ContentInfo>
                                                <HeadingInfo>
                                                    Today's Miner Locked P2X
                                                </HeadingInfo>
                                            </InfoBox>
                                        </Flex>
                                    </InfoBoxWrapper>
                                    <InfoBoxWrapper>
                                        <Flex
                                            flexDirection={
                                                isSmallScreen ? "column" : "row"
                                            }
                                            justifyContent="space-between"
                                        >
                                            <InfoBox>
                                                <ContentInfo>
                                                
                                                    {formatter.format(
                                                        _waasLobby
                                                    )}
                                                </ContentInfo>
                                                
                                                <HeadingInfo>
                                                <Flex flexDirection='row' justifyContent="space-between" alignItems='baseline'>
                                                    Today's P2X Pool {" "}(
                                                      <Text ml="5px" mr="1px" color="yellow" >{"Day " + currentDay}</Text>
                                                    )
                                                    </Flex>    
                                                </HeadingInfo>
                                                
                                            </InfoBox>
                                            <InfoBox>
                                                <ContentInfo>
                                                    {formatter.format(((Number(isNaN(yesDaily?.auctionPulse) ? 0 : yesDaily?.auctionPulse) ?? 0) / 10 ** 18) * 0.8) }
                                                </ContentInfo>
                                                <HeadingInfo>
                                                    Today's Available PLS
                                                </HeadingInfo>
                                            </InfoBox>
                                            <InfoBox>
                                                <ContentInfo>
                                                {formatter.format(Number(globals?.stakeSharesTotal ?? 0) / (10 ** 18))}
                                                </ContentInfo>
                                                <HeadingInfo>
                                                    Total Miner locked P2X
                                                </HeadingInfo>
                                            </InfoBox>
                                        </Flex>
                                    </InfoBoxWrapper>
                                </BoxLeft>
                                <BoxRight>
                                    <Flex flexDirection="column">
                                        <CountdownContainer>
                                            <Header>
                                                <EndsInText>Ends In</EndsInText>
                                                <ClockIcon
                                                    loading="lazy"
                                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/157b78abcb0c290624ee6b701ade05be3310be878ee263342ec88371f8de6beb?apiKey=c2eace46523148b195c70f9101a6de88&"
                                                />
                                            </Header>
                                            <TimeContainer>
                                            <Countdown
                                                date={Date.now() + countdowntime}
                                                renderer={renderer}
                                            />                                                
                                            {/* {index <
                                                            timeUnits.length -
                                                                1 && (
                                                            <DividerIcon
                                                                loading="lazy"
                                                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/fd478029cbe411d689676816713fb92971dbbb9da111e4d020922bc82454c908?apiKey=c2eace46523148b195c70f9101a6de88&"
                                                            />
                                                        )} */}
                                            </TimeContainer>
                                        </CountdownContainer>
                                    </Flex>
                                </BoxRight>
                            </DashInfoWrapper>



                        
                            

                        </Flex>












          </Flex>
        


          
          {currentDay > 0 && (
            <>



























            
                    <AuctionEntryWrapper>
                    <LD_WP_Title>
                      Pulse Miner
                    </LD_WP_Title>                       

                        
                    <Flex flexDirection="row" justify="space-between">
                        <Flex ml="10px">
                          <ReactiveButton idleText={
                              <Flex alignItems='center'>
                                  <Text fontSize="16px" ml="3px" color="white" bold>Referal - Bonus</Text>
                              </Flex>
                              } onClick={() => setIsNetworkSelectModalOpen(true)} color="green" outline />
                        </Flex>

                        <Flex ml="30px">
                          <FormGroup>
                            <FormControlLabel
                                control={
                                  <Switch
                                      checked={stakedDays === 7777 ? true : false}
                                      size="medium"
                                      color="warning"
                                      onClick={() => (
                                        setIsSelectModalOpen(
                                            true
                                        )
                                    )}
                                  />
                                }
                                label="Auto Compound"
                            />
                          </FormGroup>
                        </Flex>                        
                    </Flex>                        
                        
                        
                        <Flex mt="10">
                            {
                                <MantineProvider
                                    theme={{
                                        colorScheme: "dark",
                                        colors: {
                                            dark: [
                                                "#d5d7e0",
                                                "#acaebf",
                                                "#8c8fa3",
                                                "#666980",
                                                "#4d4f66",
                                                "#34354a",
                                                "#221f1d",
                                                "#221f1d",
                                                "#0c0d21",
                                                "#01010a",
                                            ],
                                        },
                                        primaryColor: "dark", // Set primary color
                                    }}
                                >
                                    <ThemeProvider theme={theme}>
                                        <MantineReactTable
                                            zIndex={99}
                                            mt="10"
                                            table={table}
                                            
                                            
                                        />
                                    </ThemeProvider>
                                </MantineProvider>
                            }
                        </Flex>
                    </AuctionEntryWrapper>
            






              <ReactModal isOpen={isNetworkSelectModalOpen} onRequestClose={() => closeModal()} style={customStyles}>
                <Flex flexDirection="row" className="accountModal">
                  
                  {true && (
                    <>

                                     
                        {Genesis.map((character, index) => (
                              <Flex   mr='20px' key={index}>
                        <Card refID={refID} character={character} index={index + 3} levels={'Genesis'} referCard={true} gameContract={gameContract} currentDay={currentDay} lastDayEntered={lastDayEntered} />
                        </Flex>
                        ))}
                        

                       
                                                      
                        {BonusYielder.map((character, index) => (
                            <Flex mr='20px' key={index}>  
                                <Card  character={character} index={index} levels={'BonusYielder'} gameContract={gameContract} currentDay={currentDay} lastDayEntered={lastDayEntered} lobbyWallet={lobbyWallet}/>

                                </Flex>
                        ))}
                       

                      <FiveDayBonusWrapper>
                        <FD_Wrapper_Title>
                            5 Days <FD_Wrapper_Bonus>Bonus</FD_Wrapper_Bonus>
                        </FD_Wrapper_Title>
                        <FD_Wrapper_Section>
                            {/* {databonus.map((item, index) => (
                                <StatCard
                                    key={index}
                                    label={item.label}
                                    separator={item.separator}
                                    value={item.value}
                                    percentage={item.percentage}
                                    amount={item.amount}
                                    unit={item.unit}
                                />
                            ))} */}

                            <StatCard
                                label={walletList?.[4] ?? 5}
                                separator={"-"}
                                value={"20"}
                                percentage={"%"}
                                amount={formatter.format(
                                    (Number(
                                        bonusWallets?.[walletList?.[4]]
                                            ?.rawAmount ?? 0
                                    ) /
                                        10 ** 18) *
                                        0.2
                                )}
                                unit={""}
                            />
                            <StatCard
                                label={walletList?.[3] ?? 4}
                                separator={"-"}
                                value={"40"}
                                percentage={"%"}
                                amount={formatter.format(
                                    (Number(
                                        bonusWallets?.[walletList?.[3]]
                                            ?.rawAmount ?? 0
                                    ) /
                                        10 ** 18) *
                                        0.4
                                )}
                                unit={""}
                            />
                            <StatCard
                                label={walletList?.[2] ?? 3}
                                separator={"-"}
                                value={"60"}
                                percentage={"%"}
                                amount={formatter.format(
                                    (Number(
                                        bonusWallets?.[walletList?.[2]]
                                            ?.rawAmount ?? 0
                                    ) /
                                        10 ** 18) *
                                        0.6
                                )}
                                unit={""}
                            />
                            <StatCard
                                label={walletList?.[1] ?? 2}
                                separator={"-"}
                                value={"80"}
                                percentage={"%"}
                                amount={formatter.format(
                                    (Number(
                                        bonusWallets?.[walletList?.[1]]
                                            ?.rawAmount ?? 0
                                    ) /
                                        10 ** 18) *
                                        0.8
                                )}
                                unit={""}
                            />
                            <StatCard
                                label={walletList?.[0] ?? 1}
                                separator={"-"}
                                value={"100"}
                                percentage={"%"}
                                amount={formatter.format(
                                    (Number(
                                        bonusWallets?.[walletList?.[0]]
                                            ?.rawAmount ?? 0
                                    ) /
                                        10 ** 18) *
                                        1
                                )}
                                unit={""}
                            />
                        </FD_Wrapper_Section>
                    </FiveDayBonusWrapper>

                    </>
                  )}  




                </Flex>
              </ReactModal>
          













              <ReactModal
                        isOpen={isSelectModalOpen}
                        onRequestClose={() => closeModal()}
                        style={customStyles}
                    >
                        <Flex flexDirection="column" className="accountModal">
                            <Flex
                                alignItems="center"
                                mb="20px"
                                justifyContent="space-between"
                            >

                                        <Text
                                            fontSize="24px"
                                            ml="5px"
                                            mr="30px"
                                            color="white"
                                            bold
                                        >
                                            AUTO COMPOUND
                                        </Text>


                            </Flex>


                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Button
                                    primary
                                    className={`orange_btn btn_Duelists`}                                
                                    size="large"
                                    onClick={() => writeSwitchModal()}
                                    color="green"
                                    outline
                                    rounded
                                >
                                  Switch {stakedDays === 7777 ? "Off" : "On"}
                                    
                                </Button>


                            </Flex>
                        </Flex>
                    </ReactModal>













            </>
          
          )}
        </Flex>
          )}
        <Text ml="5px" mr="1px" color="red" bold>{"version Beta_1.7"}</Text>  
      </ScrollArea>  
    );
  };





  const CountdownContainer = styled.section`
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2), 0 2px 14px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(65, 59, 55, 0.3);
  background-color: rgba(65, 59, 55, 0.3);
  display: flex;
  flex-direction: column;
  // max-width: 339px;
  padding: 24px 0 24px 24px;
`;

const Header = styled.header`
  display: flex;
  gap: 8px;
  color: #c2b7b1;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  padding-right: 80px;
`;

const EndsInText = styled.span`
    font-family: "Inter", sans-serif;
    color: white;
`;

const ClockIcon = styled.img`
    width: 16px;
    height: 16px;
    object-fit: auto;
    object-position: center;
    margin: auto 0;
`;

const TimeContainer = styled.div`
    display: flex;
    gap: 18px;
    margin-top: 24px;
    padding-left: 10px;
    white-space: nowrap;
`;

const TimeUnit = styled.div`
    display: flex;
    flex-direction: column;
`;

const TimeValue = styled.span`
    border: 1px solid rgba(65, 59, 55, 1);
    border-radius: 6px;
    color: yellow;
    text-align: right;
    padding: 10px;
    font: 300 48px "Inter", sans-serif;
`;

const TimeLabel = styled.span`
    color: rgba(199, 185, 178, 0.8);
    text-align: center;
    margin-top: 10px;
    font: 500 16px/150% "Inter", sans-serif;
`;

const DividerIcon = styled.img`
  width: 2px;
  aspect-ratio: 0.33;
  object-fit: auto;
  object-position: center;
  fill: #c2b7b1;
  align-self: start;
  margin-top: 15px;
`;
const DashInfoHeading = styled.div`
  padding-right: 160px;
  padding-left: 160px;
  @media screen and (max-width: 1250px) {
      padding-right: 80px !important;
      padding-left: 80px !important;
  }
  @media screen and (max-width: 991px) {
      padding-right: 60px !important;
      padding-left: 60px !important;
  }
  @media screen and (max-width: 600px) {
      padding-right: 40px !important;
      padding-left: 40px !important;
  }
`;

const DashInfoWrapper = styled(Flex)`
  width: 100%;
  padding-right: 160px;
  padding-left: 160px;
  @media screen and (max-width: 1250px) {
      padding-right: 80px !important;
      padding-left: 80px !important;
  }
  @media screen and (max-width: 991px) {
      padding-right: 60px !important;
      padding-left: 60px !important;
  }
  @media screen and (max-width: 600px) {
      padding-right: 40px !important;
      padding-left: 40px !important;
  }
  @media screen and (max-width: 991px) {
      display: flex;
      flex-direction: column;
  }
`;
const BoxLeft = styled.div`
  width: 75%;
  padding-right: 10%;
  @media screen and (max-width: 991px) {
      width: 100%;
      padding-right: 0;
      margin-top: 50px;
  }
`;
const BoxRight = styled.div`
  width: 25%;
  @media screen and (max-width: 991px) {
      width: 100%;
      max-width: 275px;
  }
`;
const HeadingInfo = styled.div`
  color: #c7b9b2;
  font-size: 14px;
`;
const ContentInfo = styled.div`
  font-size: 34px;
  margin-bottom: 5px;
`;
const InfoBox = styled.div`
  margin-bottom: 20px;
  margin-right: 30px;
`;
const InfoBoxWrapper = styled.div`
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 2px solid rgba(65, 59, 55, 0.4);
  &:last-child {
      border-bottom: 0;
  }
`;

const FiveDayBonusWrapper = styled.div`
  background: #141413;
  padding-top: 80px;
  padding-bottom: 80px;
  padding-right: 160px;
  padding-left: 160px;
  @media screen and (max-width: 1250px) {
      padding-right: 80px !important;
      padding-left: 80px !important;
  }
  @media screen and (max-width: 991px) {
      padding-right: 60px !important;
      padding-left: 60px !important;
  }
  @media screen and (max-width: 600px) {
      padding-right: 40px !important;
      padding-left: 40px !important;
  }
`;

const FD_Wrapper_Title = styled.h1`
  color: var(--Brand-Lighter, #ff6e36);
  letter-spacing: -0.72px;
  width: 100%;
  font: 600 36px/60px Inter, sans-serif;
  margin: 0;
  @media (max-width: 991px) {
      max-width: 100%;
  }
`;

const FD_Wrapper_Bonus = styled.span`
  font-weight: 300;
  color: rgba(255, 110, 54, 1);
`;

const FD_Wrapper_Section = styled.section`
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  width: 100%;
  // gap: 20px;
  @media (max-width: 991px) {
      max-width: 100%;
      flex-wrap: wrap;
      margin-top: 40px;
  }
`;

const FD_Wrapper_Card = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  @media (max-width: 991px) {
      width: 50%;
      padding-right: 0;
      margin-top: 20px;
  }
  @media (max-width: 768px) {
      width: 100%;
  }
`;

const FD_Wrapper_Stats = styled.div`
  display: flex;
  gap: 12px;
  font-size: 18px;
  color: var(--T---Main, #c2b7b1);
  font-weight: 700;
  line-height: 38px;
`;

const FD_Wrapper_StatLabel = styled.span`
  font-family: Inter, sans-serif;
  font-weight: 500;
`;

const FD_Wrapper_StatSeparator = styled.span`
  color: var(--T---Description, rgba(199, 185, 178, 0.8));
  font-family: Inter, sans-serif;
`;

const FD_Wrapper_StatValue = styled.span`
  font-family: Inter, sans-serif;
`;

const FD_Wrapper_Amount = styled.div`
  color: #fff;
  letter-spacing: -0.72px;
  margin-top: 24px;
  font: 600 36px/44px Inter, sans-serif;
`;

const FD_Wrapper_ImportantValue = styled.span`
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 1.2px;
`;

const LobbyDayOuterWrapper = styled.div`
  padding-top: 60px;
  padding-right: 160px;
  padding-left: 160px;
  @media screen and (max-width: 1250px) {
      padding-right: 80px !important;
      padding-left: 80px !important;
  }
  @media screen and (max-width: 991px) {
      padding-right: 60px !important;
      padding-left: 60px !important;
  }
  @media screen and (max-width: 600px) {
      padding-right: 40px !important;
      padding-left: 40px !important;
  }
`;
const LobbyDayWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-flow: wrap;
`;
const LD_WP_Card1 = styled.div`
  margin-left: 30px;
  width: 30%;
  max-width: 300px;
  @media screen and (max-width: 1250px) {
      margin-left: 0px;
      margin-right: 30px;
  }
  @media screen and (max-width: 991px) {
      width: 50%;
  }
  @media screen and (max-width: 768px) {
      width: 100%;
      max-width: unset;
      margin-right: 0;
      margin-bottom: 30px;
  }
`;
const LD_WP_Card2 = styled.div`
  margin-left: 30px;
  width: 30%;
  max-width: 300px;
  @media screen and (max-width: 1250px) {
      margin-left: 0px;
  }
  @media screen and (max-width: 991px) {
      width: 50%;
  }
  @media screen and (max-width: 768px) {
      width: 100%;
      max-width: unset;
  }
`;
const LD_WP_AuctionLobbyContainer_Inner = styled.div`
  max-width: 363px;
`;

const LD_WP_AuctionLobbyContainer = styled.section`
  display: flex;
  width: 40%;
  flex-direction: column;
  @media screen and (max-width: 1250px) {
      width: 100%;
      margin-bottom: 30px;
  }
`;

const LD_WP_Title = styled.h1`
  color: var(--Brand-Lighter, #ff6e36);
  text-align: left;
  width: 100%;
  font: 600 36px/150% Inter, sans-serif;
`;

const LD_WP_LobbyInfo = styled.div`
  display: flex;
  margin-top: 16px;
  gap: 8px;
  align-items: center;
  padding: 0 20px;
`;

const LD_WP_LobbyDay = styled.h2`
  color: var(--Base, #f1ede9);
  letter-spacing: -0.88px;
  font: 600 44px/136% Inter, sans-serif;
  margin: 0;
`;

const LD_WP_LobbyCount = styled.span`
  leading-trim: both;
  text-edge: cap;
  justify-content: center;
  border-radius: 6px;
  background-color: var(--Brand-Fade-10, rgba(175, 71, 38, 0.2));
  color: var(--Brand-Lighter, #ff6e36);
  white-space: nowrap;
  letter-spacing: -0.84px;
  padding: 5px 18px;
  margin-left: 10px;
  font: 300 42px/143% Inter, sans-serif;
`;

const LD_WP_Description = styled.p`
  color: var(--T---Description, rgba(199, 185, 178, 0.8));
  margin-top: 16px;
  width: 100%;
  font: 400 14px/20px Inter, sans-serif;
`;

const LD_WP_Divider = styled.hr`
  border-color: rgba(65, 59, 55, 0.4);
  background-color: rgba(65, 59, 55, 0.4);
  margin-top: 34px;
  width: 100%;
`;

const LD_WP_StatusSection = styled.section`
  color: var(--T---Description, rgba(199, 185, 178, 0.8));
  margin-top: 33px;
  width: 100%;
  font: 500 14px/143% Inter, sans-serif;
`;

const LD_WP_StatusInfo = styled.div`
  border-radius: 8px;
  display: flex;
  margin-top: 16px;
  padding-right: 20px;
  gap: 8px;
`;

const LD_WP_StatusIconContainer = styled.div`
  justify-content: center;
  align-items: center;
  border-radius: 57px;
  background-color: rgba(33, 223, 134, 0.2);
  display: flex;
  width: 24px;
  height: 24px;
  padding: 8px;
`;

const LD_WP_Icon = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 14px;
`;

const LD_WP_StatusMessage = styled.p`
  color: var(--Succsess, #21bf75);
  margin: auto 0;
  font: 400 16px Inter, sans-serif;
`;
const AuctionEntryWrapper = styled.p`
  margin-top: 30px;
  padding-right: 160px;
  padding-left: 160px;
  @media screen and (max-width: 1250px) {
      padding-right: 80px !important;
      padding-left: 80px !important;
  }
  @media screen and (max-width: 991px) {
      padding-right: 60px !important;
      padding-left: 60px !important;
  }
  @media screen and (max-width: 600px) {
      padding-right: 40px !important;
      padding-left: 40px !important;
  }
`;

const Button = styled.button`
    border-radius: 8px;
    padding: 10px 18px;
    font-weight: 600;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    background-color: ${({ primary }) => (primary ? "#d9511c" : "#171615")};
    color: ${({ primary }) => (primary ? "#f1ede9" : "#d9511c")};
    border: ${({ secondary }) => secondary && "1px solid #d9511c"};
`;




export default SelectCharacter;