import React, { useState, useEffect } from "react";
import { Flex, Text } from "crox-new-uikit";
import { AnimateKeyframes } from "react-simple-animate";
import { Icon } from "@iconify/react";
// import { Button } from "../../../components/CommonComponents";
import useMediaQuery from "use-mediaquery";
import { fenixContract } from "../../../libraries/fenixContract";
import blankImage from "../../../assets/blank.png";
import { createTheme, ThemeProvider } from "@mui/system";
import { Select, Paper } from "@mantine/core";
import TextField from "@mui/material/TextField";
import ReactModal from "react-modal";
import { BigNumber, ethers } from "ethers";
import {
    Chain,
    Address,
    useContractWrite,
    useNetwork,
    usePrepareContractWrite,
    useWaitForTransaction,
    useFeeData,
    useBalance,
    useAccount,
    useContractReads,
} from "wagmi";
import FENIX_ABI from "../../../models/abi/LobbyFacet.json";
import ReactiveButton from "reactive-button";
let isConfirm = false;
import "react-circular-progressbar/dist/styles.css";
import toast from "react-hot-toast";
import "./Card.scss";
import styled from "styled-components";

function Card(props) {
    const {
        refID,
        character,
        index,
        levels,
        referCard,
        gameContract,
        currentDay,
        lastDayEntered,
        lobbyWallet,
        walletList,
        bonusWallets,
        minerDays,
        refetchAssets,
        refetchLastDay,
        refetchGlobals,
        refetchDailyData,
        refetchWaasLobby,
        setTableUpdate,
    } = props;
    const { collapse } = props;
    const [waitWave, setWaitWave] = useState(false);
    const [success, setSuccess] = useState(false);
    const [action, setAction] = useState([]);
    const [allocation, setAllocation] = useState(0);
    //const [levels, seLevels] = useState(levels)
    const isMobile = useMediaQuery("(max-width: 600px)");

    const { chain } = useNetwork();
    const { address } = useAccount();
    const { data: feeData } = useFeeData({
        formatUnits: "gwei",
        watch: false,
        cacheTime: 60_000,
    });
    const { data: fenixBalance } = useBalance({
        address: address,
        //token: fenixContract(chain).address,
        staleTime: 20_000,
    });

    const [_disable, setDisable] = useState(false);
    const [_disableField, setDisableField] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [_inDay, setInDay] = useState(false);
    const [formInput, updateFormInput] = useState({
        lobbyAmount: 1,
        plsAmount: 1,
        referrerID: Number(refID)??0,
        tabId: 1000002,
        numOfMiners: 1,
        address: address,
    });
    const [renft, setRenft] = useState([]);
    const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);
    const [isLinkModalOpen, setIsLinkModalOpen] = useState(false);    
    const [lobbyType, setLobbyType] = useState(1);
    const [refMax, setRefMax] = useState(1);
    
    const [dividend, setDividend] = useState(0);
    const [ex, SetExes] = useState(0);
    const [dayMiners, SetDayMiners] = useState([]);

  /*** CONTRACT WRITE SETUP ***/
  
  const { config: lobbyEntryConfig, isLoading: lobbyEntryIsoading } = usePrepareContractWrite({
    address: fenixContract(chain).address,
    abi: FENIX_ABI.abi,
    chainId: chain?.id,
    functionName: "xfEnter",

    args: [(formInput.referrerID).toString(), (lobbyType).toString(), formInput.address, 
      !isNaN(Number(formInput?.lobbyAmount)) && parseFloat(formInput?.lobbyAmount) >= 1 ? 
        ethers.utils.parseEther(String(lobbyType == 0 ? 
          ((ethers.utils.parseEther(String(formInput?.lobbyAmount))).sub(ethers.utils.parseEther(Number(formInput?.lobbyAmount) >= 1 ? "0.000000001" : "0"))) / (10**18)
          : Number(formInput.lobbyAmount))) 
      : String(0)],


    //args: [(formInput.referrerID).toString(), (lobbyType).toString(), formInput.address, !isNaN(Number(formInput?.lobbyAmount)) && parseFloat(formInput?.lobbyAmount) >= 1 ? ethers.utils.parseEther(String(lobbyType == 0 ? Number(formInput.lobbyAmount) : Number(formInput.lobbyAmount))) : String(0)],
    //args: [(formInput.referrerID).toString(), (lobbyType).toString(), formInput.address, !isNaN(Number(formInput?.lobbyAmount)) && parseFloat(formInput?.lobbyAmount) >= 1 ? ethers.utils.parseEther(String(Number(formInput?.lobbyAmount))) : String(0)],
    overrides: {
      value: ethers.utils.parseEther(parseFloat(formInput.plsAmount) >= 1 ? String(Number(formInput.plsAmount)) : String(0)),
      gasLimit: 14900000,
    },
    //enabled: false,
  });

  const { data: lobbyEntryWriteData, isLoading: lobbyEntryWriteLoading, write: lobbyEntryWrite } = useContractWrite({
    ...lobbyEntryConfig,
    //onSuccess(_data) {
    //  reset;
    //  setProcessing(true);
    //  setDisabled(true);
    //},
  });

  const { isLoading: entryTxIsLoaging } = useWaitForTransaction({
    hash: lobbyEntryWriteData?.hash,
    onSuccess(data) {
      toast.success("Your entry has been claimed. Happy earning!");
      refetchAssets?.();
      refetchLastDay?.();
      refetchGlobals?.();
      refetchDailyData?.();
      refetchWaasLobby?.();   
      setTableUpdate?.(true);   
      //router.push("/lobby/active");
    },
    onError(err) {
      toast.error("Initiating entry was unsuccessful. Please try again later.");
    },    
  });

  /*** CONTRACT WRITE SETUP ***/
  
  const { config: lobbyExitConfig, isLoading: lobbyExitIsoading } = usePrepareContractWrite({
    address: fenixContract(chain).address,
    abi: FENIX_ABI.abi,
    chainId: chain?.id,
    functionName: "xfLobbyExit",
    //args: [character?.entryId, character?.lockedDay, formInput.numOfMiners],
    args: [character?.entryId, formInput.numOfMiners],
    overrides: {
      //value: ethers.utils.parseEther(formInput.plsAmount),
      gasLimit: 30000000,
    },
    //enabled: false,
  });

  const { data: lobbyExitWriteData, isLoading: lobbyExitWriteLoading, write: lobbyExitWrite } = useContractWrite({
    ...lobbyExitConfig,
    //onSuccess(_data) {
    //  reset;
    //  setProcessing(true);
    //  setDisabled(true);
    //},
  });

  const { isLoading: exitTxIsLoaging } = useWaitForTransaction({
    hash: lobbyExitWriteData?.hash,
    onSuccess(data) {
      toast.success("Your auction to miner has been claimed. Happy earning!");
      refetchAssets?.();
      refetchLastDay?.();
      refetchGlobals?.();
      refetchDailyData?.();
      refetchWaasLobby?.();      
      setTableUpdate?.(true);
      //router.push("/lobby/active");
    },
    onError(err) {
      toast.error("Auction claim was unsuccessful. Please try again later.");
    },    
  });


  let formatter = Intl.NumberFormat('en', { notation: 'compact', minimumFractionDigits: 2, maximumFractionDigits: 2 });
  let formatter_4 = Intl.NumberFormat('en', { notation: 'compact', minimumFractionDigits: 4, maximumFractionDigits: 4 });
  let formatter_6 = Intl.NumberFormat('en', { notation: 'compact', minimumFractionDigits: 6, maximumFractionDigits: 6 });


  const typehHandler = (event) => {
    if(character.entryType == 0) {
      if(referCard == true) {
        setLobbyType(0);
        formInput.referrerID = Number(character?.entryId);
      }
    }
    if(character.entryType == 1 || (character.entryType == 0 && referCard == false)) setLobbyType(1);
    if(character.entryType == 4) {
      setLobbyType(3);
      if(formInput.referrerID === 0)
      formInput.referrerID = Number(lobbyWallet);
    }
    if(character.entryType == 5) setLobbyType(2);

    //console.log("character.entryType", formInput.referrerID, character.entryType, lobbyType, referCard)

  };
    
    //const handleLogin = () => {
    //    isConfirm = true
    //    localStorage.setItem("accountStatus", "1");
    //    return connect({ connector });
    //}

    //const handleLogout = () => {
    //    isConfirm = false
    //    localStorage.removeItem("accountStatus")
    //    //disconnect();
    //}

    function copyToClipBoard() {
        
        var x = document.getElementById("snackbar");
        x.className = "show";
        setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);

        if(isLinkModalOpen){
          var y = document.getElementById("copie");
          y.className = "show";
          setTimeout(function () { y.className = y.className.replace("show", ""); }, 3000);        
  
        }
    }


    const handlePaste = (e) => {
        e.preventDefault();
      };

    function closeModal() {
        setIsSelectModalOpen(false);
    }

    function closeLinkModal() {
        //setIsNetworkSelectModalOpen(false);
        //setIsSelectModalOpen(false);
        setIsLinkModalOpen(false);
    }

    const customStyles = {
        overlay: {
            position: 'fixed',
            zIndex: 1000,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 1)'
        },        
        content: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            right: 'auto',
            bottom: 'auto',
            
            border: 'none',
            overflow: 'hidden',
            backgroundColor: 'rgba(0, 0, 0, 1)',
            

            position: 'absolute',



            WebkitOverflowScrolling: 'touch',

            outline: 'none',




        },
      
    };

    ReactModal.defaultStyles.overlay.backgroundColor = 'rgba(0,0,0,0)';




  //console.log(chain);
  //console.log(fenixContract(chain).address);
  //console.log("dayMiners", dayMiners, Number(dayMiners?.[1]));
  //console.log("...walletList", walletList, Number(character?.oneFiveX), walletList?.indexOf(Number(character?.oneFiveX)), Number(bonusWallets?.[Number(character?.oneFiveX)]?.rawAmount));
  
   
  //console.log("+++Bonus", (((5-walletList?.indexOf(Number(character?.oneFiveX)))/5) * Number(bonusWallets?.[Number(character?.oneFiveX)]?.rawAmount)  / (10**18)) / Number(dayMiners?.[1]))
  //console.log("+++Bonus", walletList?.indexOf(Number(character?.oneFiveX)));
  
  //console.log("+++Bonus", (((5-walletList?.indexOf(Number(character?.oneFiveX)))/5) * Number(bonusWallets?.[Number(character?.oneFiveX)]?.rawAmount)  / (10**18)) / Number(dayMiners?.[1]))
  // Wallet #, Wallet veiw index, 
  // Number(bonusWallets?.[walletList?.[Number(character?.oneFiveX) - 1]]?.rawAmount), Number(character?.oneFiveX));
  console.log("dividend", dividend, Number(character?.stakeShares), character?.lockedDay, currentDay);
  console.log('CARD_RefID:', refID)  
  //console.log("character....", character);
  //console.log("formInput_CARD", formInput, character?.lockedDay, lobbyType);
  //console.log("xfEnter_CARD", (formInput.referrerID).toString(), (lobbyType).toString(), formInput.address, String(formInput?.lobbyAmount))
  //console.log("currentDay, lastDayEntered", currentDay, lastDayEntered?.[0]?.day, _inDay);
  //console.log("Allocation", character)

  //console.log(character?.entryId, "...Allocation ", character?.lockedDay )
  //console.log("Ex...", ex);  

  console.log("xfLobbyExit", character?.entryId, formInput.numOfMiners);



 

/*
useEffect(() => {

  if(character?.status === 7) SetExes((parseInt(character?.stakeShares?._hex, 16) / (10 ** 18)) / (parseInt(character?.rawAmount?._hex, 16) / (10 ** 18)));
  else SetExes(Number(dividend) / (parseInt(character?.rawAmount?._hex, 16) / (10 ** 18)));
  

}, [character, dividend])
*/



useEffect(() => {
  let val = "";
  let calc = 0;
  if (character) {
    if(levels == 'Duelists' && (character.entryType === 0 || character.entryType === 1)) {
      val = [1, "Add PLS", "Lobby Entry "];
      setDisable((Number(currentDay) !== Number(character.lockedDay)));
    };
    if(levels == 'Duelists' && character.entryType === 5) {
      val = [1, "Add PLS", "Yield Lobby "];
      setDisable((Number(currentDay) !== Number(character.lockedDay)));
    };
    if(levels == 'BonusYielder' && character.entryType === 4) {
      val = [1, "Add PLS", "Bonus Yield Card"];
      setDisable((Number(currentDay) !== Number(character.lockedDay)));
    };
    if(levels == 'Initiators') {
      val = [1, "Mine PLS", "Auction Entry "];
      calc = (Number(character.rawAmount) / 10 ** 18) * ( ((Number(character?.price) / 10 ** 18) )  );
      setAllocation(calc);
    };
    if(levels == 'Referrers') {
      val = [1, "Enter Lobby"];
    };
    if(levels == 'Controllers') {
      val = [1, "End & Collect", "MINERS "];
      calc = (Number(character.rawAmount) / 10 ** 18) * ( ((Number(character?.price) / 10 ** 18) )  );
      setAllocation(calc);      
    };
    if(levels == 'Sentials') {
      val = [1, "2X Exit", "Miners "];
    };

    if(character.entryType == 0 && referCard == true) {
      val = [1, "Redeem P2X"];
    }    
    setAction(val);
  }
  
  if(character?.entryType == 0 && !isNaN(Number(character?.price)) && !isNaN(Number(character?.refBonus)) && parseFloat(Number(character?.refBonus)) >= 1  && parseFloat(Number(character?.price)) >= 1){
    if(((Number(character?.refBonus)  / (10**18)) / (Number(character?.price) / 10 ** 18)) > 8000000000 ){
      updateFormInput({ ...formInput, lobbyAmount: Number(8000000000 * Number(character?.price)  / (10**18))});
      setRefMax((8000000000 * Number(character?.price)  / (10**18)));
    
    } else {
      updateFormInput({ ...formInput, lobbyAmount: Number((Number(character?.refBonus)  / (10**18)) )});
      setRefMax((Number(character?.refBonus)  / (10**18)) );
    }
  }
    

}, [character])


useEffect(() => {
  if (levels == "Duelists" && currentDay != lastDayEntered?.[0]?.day) {
      setDisableField(true);
  }
}, [currentDay, lastDayEntered?.[0]])  



    //const askContractToInitNfts = () => {
    //  //console.log("Submit");
    //  writeLobby();
    //};

    //console.log("CARD", character);

    //useEffect(() => {
    //    setupEventListener()
    //})
    return (
        <Flex
            className={`card_parent_${String(character.entryType)}_${levels}`}
            justifyContent="left"
            onFocus={() => typehHandler()}
            onMouseEnter={() => typehHandler()}
            onTouchStart={() => typehHandler()}
        >
            {levels === "Initiatorx" ? (
                <>
                    <div className="initiatorx_wrapper">
                        <div className="bxwrapper">
                            <div className="value">{character.lockedDay}</div>
                            <div
                                className="heading"
                                style={{ color: "#d9511c !important" }}
                            >
                                Day Number
                            </div>
                        </div>
                        <div className="bxwrapper">
                            <div className="value">
                                {Number(character.groupId)}
                            </div>
                            <div className="heading">Index ID</div>
                        </div>
                        <div className="bxwrapper">
                            <div className="value">
                                {Number(character.entryId)}
                            </div>
                            <div className="heading">Entry ID</div>
                        </div>
                        <div className="bxwrapper">
                            <div className="value">
                                {Number(character.referenceId)}
                            </div>
                            <div className="heading">Referrer ID</div>
                        </div>
                        <div className="bxwrapper">
                            <div className="value">
                                {formatter_4.format(
                                    parseInt(character?.rawAmount?._hex, 16) /
                                        10 ** 18
                                )}
                            </div>
                            <div className="heading">PLS In</div>
                        </div>
                        <div className="bxwrapper">
                            <div className="value">
                                {allocation > 0
                                    ? formatter.format(allocation)
                                    : "Pending"}
                            </div>
                            <div className="heading">P2X</div>
                        </div>
                        <div className="bxwrapper">
                            {allocation > 0 && (
                                <Button
                                    primary
                                    className={`orange_btn btn_${levels}`}
                                    onClick={() => setIsSelectModalOpen(true)}
                                >
                                    <svg
                                        width="22"
                                        height="23"
                                        viewBox="0 0 22 23"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M11 1.5L21 8M11 1.5L1 8M11 1.5V8M21 8V15M21 8L11 15M21 15L11 21.5M21 15L11 8M11 21.5L1 15M11 21.5V15M1 15V8M1 15L11 8M1 8L11 15"
                                            stroke="#F1EDE9"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>

                                    {action[1]}
                                </Button>
                            )}
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <Flex
                        className={`refcardsWrapper card_parent_${String(
                            character.entryType
                        )}_${levels}`}
                        justifyContent="center"
                    >
                        <Flex
                            className={`card_child card_child_${levels}`}
                            justifyContent="center"
                        >
                            <Flex
                                flexDirection="column"
                                className={`gameCard card_${String(
                                    character.entryType
                                )}_${levels}`}
                                width="100%"
                                alignItems="center"
                                mr="10px"
                                ml="10px"
                            >
                                {levels === "Sentinels" && (
                                    <Icon
                                        icon="ant-design:heart-filled"
                                        color="#ff4655"
                                        style={{ fontSize: "24px" }}
                                        className="card_icon"
                                    />
                                )}
                                <Flex
                                    className="referCardHeading"
                                    alignItems="center"
                                    mb="10px"
                                >
                                    {character.entryType === 0 &&
                                    referCard === true ? (
                                        <>
                                            <Flex
                                                flexDirection="column"
                                                alignItems="center"
                                                justifyContent="center"
                                                style={{ width: "100%" }}
                                            >
                                                <Text
                                                    fontSize="18px"
                                                    m="0 10px"
                                                    bold
                                                >
                                                    Click To Copy Link
                                                </Text>
                                            </Flex>
                                            <div
                                                onClick={() => {
                                                    navigator.clipboard.writeText(
                                                        "http://testnet2.tru2x.io/lobby?referralID=" +
                                                            Number(
                                                                character.entryId
                                                            )
                                                    );
                                                    setIsLinkModalOpen(
                                                      true
                                                  ) 
                                                }}
                                                className="cursor-pointer"
                                            >
                                                <Icon
                                                    icon="fluent:copy-24-filled"
                                                    color="rgba(199, 185, 178, 0.8)"
                                                    width="30"
                                                    height="30"
                                                />
                                            </div>
                                        </>
                                    ) : character?.levels === "BonusYielder" ? (
                                        <Flex
                                            flexDirection="column"
                                            className=""
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Text fontSize="18px" ml="3px" bold>
                                                {action[2]}
                                            </Text>
                                            <Text fontSize="18px" ml="3px" bold>
                                                {"Next Wallet ID: "}
                                            </Text>
                                        </Flex>
                                    ) : character?.levels === "Duelists" ||
                                      character?.levels === "Initiators" ? (
                                        <Flex
                                            flexDirection="column"
                                            className=""
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Text fontSize="18px" ml="3px" bold>
                                                {action[2] +
                                                    Number(character.groupId)}
                                            </Text>
                                            <Text fontSize="18px" ml="3px" bold>
                                                {"ID: " +
                                                    Number(character.entryId) +
                                                    " | Ref: " +
                                                    character.referenceId}
                                            </Text>
                                {/*+" "+(Number(character._dayP2XPool) / 10 ** 18)+" "+(Number(character.rawAmount) / 10 ** 18)+" "+(Number(character.auctionPulse) / 10 ** 18)*/}
                                        </Flex>
                                    ) : (
                                        <Flex
                                            flexDirection="column"
                                            className=""
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Text fontSize="18px" ml="3px" bold>
                                                {action[2] +
                                                    " Qty: (" +
                                                    Number(character.numItems) +
                                                    ") "}
                                            </Text>
                                            {/*<Text fontSize="18px" ml='3px' bold>{levels+" "+character.status+" "+character.entryType+" ID: "+Number(character.entryId)}</Text>*/}
                                            <Text fontSize="18px" ml="3px" bold>
                                                {" ID#: " +
                                                    Number(character.entryId) +
                                                    " | BW:" +
                                                    (character?.oneFiveX ?? 0) +
                                                    " | " +
                                                    (Number(
                                                        character?.oneFiveX
                                                    ) === 0
                                                        ? 0
                                                        : ((5 -
                                                              walletList?.indexOf(
                                                                  Number(
                                                                      character?.oneFiveX
                                                                  )
                                                              )) /
                                                              5) *
                                                          100) +
                                                    "%"}
                                            </Text>

                                            <Text fontSize="18px" ml="3px" bold>
                                                {" Index#: " +
                                                    Number(
                                                        character.headIndex
                                                    ) +
                                                    " - " +
                                                    Number(character.tailIndex)}
                                            </Text>
                                            <Text
                                                fontSize="18px"
                                                ml="3px"
                                                color="black"
                                                bold
                                            >
                                                {"P2X Pool: " +
                                                    formatter.format(
                                                        parseInt(
                                                            character
                                                                ?._dayP2XPool
                                                                ?._hex,
                                                            16
                                                        ) /
                                                10 ** 18
                                                    )}
                                            </Text>
                                            <Text
                                                fontSize="18px"
                                                ml="3px"
                                                color="black"
                                                bold
                                            >
                                                {"DIV Pool: " +
                                                    formatter.format(
                                                        parseInt(
                                                            character
                                                                ?.dayDividends
                                                                ?._hex,
                                                            16
                                                        ) /
                                                            10 ** 18
                                                    )}
                                            </Text>
                                        </Flex>
                                    )}
                                </Flex>
                                <Flex
                                    flexDirection="column"
                                    className="refNameWrapper"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    {character.entryType === 0 &&
                                    referCard === true ? (
                                        <Text
                                            fontSize="100px"
                                            mb="20px"
                                            color="black"
                                            className="refName"
                                            bold
                                        >
                                            {Number(character.entryId)}
                                            <h5>Referral ID</h5>
                                        </Text>
                                    ) : character?.levels === "BonusYielder" ? (
                                        <Text
                                            fontSize="100px"
                                            mb="20px"
                                            color="black"
                                            className="refName"
                                            bold
                                        >
                                            {Number(lobbyWallet)}
                                            <h5>Day Number</h5>
                                        </Text>
                                    ) : character?.levels === "Initiators" ? (
                                        <Text
                                            fontSize="100px"
                                            mb="20px"
                                            color="black"
                                            className="initiatorsName"
                                            bold
                                        >
                                            {character.lockedDay}
                                            <h5>Day Number</h5>
                                        </Text>
                                    ) : (
                                        <Text
                                            fontSize="100px"
                                            mb="20px"
                                            color="black"
                                            className="refName"
                                            bold
                                        >
                                            {character.lockedDay}
                                            <h5>Day Number</h5>
                                        </Text>
                                    )}

                                    {levels === "Sentinels" ? (
                                        <AnimateKeyframes
                                            play
                                            iterationCount="infinite"
                                            direction="alternate"
                                            duration={2}
                                            keyframes={[
                                                "transform: scale(1)",
                                                "transform: scale(1.2)",
                                            ]}
                                        >
                                            <Flex>
                                                <img
                                                    src={character.imageURI}
                                                    alt={character.lockedDay}
                                                />
                                            </Flex>
                                        </AnimateKeyframes>
                                    ) : (
                                        <img
                                            src={blankImage}
                                            alt={character.lockedDay}
                                        />
                                    )}
                                </Flex>

                                <div className="refBottomWrapper">
                                    {character.entryType === 0 &&
                                    referCard === true ? (
                                        <>
                                            <div className="entryPriceWrapper">
                                                {formatter.format(
                                                    parseInt(
                                                        character?.refBonus
                                                            ?._hex,
                                                        16
                                                    ) /
                                            10 ** 18
                                                )}
                                                <h6>Referral Bonus</h6>
                                            </div>
                                        </>
                                    ) : levels === "Duelists" ||
                                      levels === "BonusYielder" ? (
                                        <>
                                            <div className="entryPriceWrapper">
                                                {formatter_4.format(
                                                    parseInt(
                                                        character?.rawAmount
                                                            ?._hex,
                                                        16
                                                    ) /
                                                        10 ** 18
                                                )}
                                                <h6>Entry Price</h6>
                                            </div>
                                        </>
                                    ) : levels === "Initiators" ? (
                                        <>
                                            <Flex
                                                flexDirection="column"
                                                className=""
                                                alignItems="left"
                                                justifyContent="center"
                                            >


                                            <div className="entryPriceWrapper">
                                            <h6>Entry Price</h6>
                                                {formatter_4.format(
                                                    parseInt(
                                                        character?.rawAmount
                                                            ?._hex,
                                                        16
                                                    ) /
                                                        10 ** 18
                                                )}
                                               
                                            </div>




                                                <Flex alignItems="center" justifyContent="center" >

                                                    <Icon style={{ fontSize: "24px" }} icon="fluent-emoji:dollar-banknote" />
                                                    {allocation > 0 ? (
                                                        <Text
                                                            fontSize="22px"
                                                            ml="5px"
                                                            mr="30px"
                                                            color="green"
                                                            bold
                                                        >
                                                            {formatter.format(
                                                                allocation
                                                            )+" P2X"} 
                                                        </Text>
                                                        
                                                    ) : (
                                                        <Text
                                                            fontSize="14px"
                                                            ml="5px"
                                                            mr="30px"
                                                            bold
                                                        >
                                                            Pending
                                                        </Text>
                                                    )}
                                                </Flex>
                                            </Flex>
                                        </>
                                    ) : (
                                        <></>
                                    )}

                                    {/*<Icon icon="noto:crossed-swords" />
                                        <Text fontSize="14px" ml='5px' bold>{character.attackDamage}</Text>*/}

                                    <Flex
                                        className="refBottomBtnWrapper"
                                        m={isMobile ? "20px 10px" : "20px"}
                                    >
                                        {character.entryType === 0 &&
                                        referCard === true ? (
                                            <Button
                                                primary
                                                className={`orange_btn btn_${levels}`}
                                                onClick={() =>
                                                    setIsSelectModalOpen(true)
                                                }
                                            >
                                                {action[1]}
                                            </Button>
                                        ) : levels === "Initiators" ? ( // ...or Bonus Yielder
                                            allocation > 0 && (
                                                <Button
                                                    primary
                                                    className={`orange_btn btn_${levels}`}
                                                    onClick={() =>
                                                        setIsSelectModalOpen(
                                                            true
                                                        )
                                                    }
                                                >
                                                    {action[1]}
                                                </Button>
                                            )
                                        ) : character.entryType === 4 ? ( // ...or Bonus Yielder
                                            <Button
                                                primary
                                                className={`orange_btn btn_${levels}`}
                                                onClick={() =>
                                                    setIsSelectModalOpen(true)
                                                }
                                            >
                                                {action[1]}
                                            </Button>
                                        ) : (
                                            <Button
                                                primary
                                                color="#fff"
                                                disabled={
                                                    _disable ? true : false
                                                }
                                                className={
                                                    _disable
                                                        ? ""
                                                        : `orange_btn btn_${levels}`
                                                }
                                                onClick={() =>
                                                    setIsSelectModalOpen(true)
                                                }
                                            >
                                                {_disable ? "" : action[1]}
                                            </Button>
                                        )}

                                    </Flex>
                                </div>
                            </Flex>
                        </Flex>
                    </Flex>
                </>
            )}
            <ReactModal
                isOpen={isSelectModalOpen}
                onRequestClose={() => closeModal()}
                style={customStyles}
            >
                <Flex flexDirection="column" className="accountModal">
                    {levels === "Initiators" ? (
                        <>
                            <Flex
                                alignItems="center"
                                mb="20px"
                                justifyContent="space-between"
                            >
                                <Text
                                    fontSize="24px"
                                    ml="5px"
                                    mr="30px"
                                    color="#fff"
                                    bold
                                >
                                    EXIT LOBBY - MINE PLS
                                </Text>
                            </Flex>
                            <Flex
                                alignItems="center"
                                mb="20px"
                                justifyContent="space-between"
                            >
                                <Text
                                    fontSize="14px"
                                    ml="5px"
                                    mr="30px"
                                    color="#FF6E36"
                                    bold
                                >
                                    Auction ID:{" "}
                                </Text>
                                <Text fontSize="14px" ml="5px" mr="30px" bold>
                                    {parseInt(character?.entryId?._hex, 16)}
                                </Text>
                            </Flex>
                            <Flex
                                alignItems="center"
                                mb="20px"
                                justifyContent="space-between"
                            >
                                <Text
                                    fontSize="14px"
                                    ml="5px"
                                    mr="30px"
                                    color="#FF6E36"
                                    bold
                                >
                                    Allocation:{" "}
                                </Text>
                                <Text fontSize="14px" ml="5px" mr="30px" bold>
                                    {formatter_4.format(allocation) + " P2X"}
                                </Text>
                            </Flex>
                            <Flex
                                
                                mb="20px"
                                justifyContent="space-between"
                                flexDirection="column"
                            >
                                <label
                                    htmlFor="num-of-miners-input"
                                    style={{
                                        color: "#FF6E36",
                                        marginBottom: "16px",
                                    }}
                                >
                                    Enter # of Miners to Start
                                </label>
                                <TextField
                                    id="num-of-miners-input"
                                    defaultValue={formInput.numOfMiners}
                                    variant="filled"
                                    type="number"
                                    sx={{
                                        input: {
                                            color: "white",
                                        },
                                    }}
                                    className="inputField"
                                    onChange={(e) =>
                                        updateFormInput({
                                            ...formInput,
                                            numOfMiners: Number(e.target.value),
                                        })
                                    }
                                />
                                {/* <TextField
                                    label="Enter # of Miners to Start"
                                    defaultValue={formInput.numOfMiners}
                                    variant="filled"
                                    type="number"
                                    sx={{
                                        input: {
                                            color: "white",
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: "#ff4655",
                                        },
                                    }}
                                    className="inputField"
                                    onChange={(e) =>
                                        updateFormInput({
                                            ...formInput,
                                            numOfMiners: Number(e.target.value),
                                        })
                                    }
                                /> */}
                            </Flex>
                        </>
                    ) : levels === "Controllers" ? (
                        <>
                            <Flex
                                alignItems="center"
                                mb="20px"
                                justifyContent="space-between"
                            >
                                <Text
                                    fontSize="24px"
                                    ml="5px"
                                    mr="30px"
                                    color="#fff"
                                    bold
                                >
                                    END MINER - COLLECT PLS
                                </Text>
                            </Flex>
                            <Flex
                                alignItems="center"
                                mb="20px"
                                justifyContent="space-between"
                            >
                                <Text
                                    fontSize="14px"
                                    ml="5px"
                                    mr="30px"
                                    color="#FF6E36"
                                    bold
                                >
                                    Miner ID:{" "}
                                </Text>
                                <Text fontSize="14px" ml="5px" mr="30px" bold>
                                    {parseInt(character?.entryId?._hex, 16)}
                                </Text>
                            </Flex>
                            <Flex
                                alignItems="center"
                                mb="20px"
                                justifyContent="space-between"
                            >
                                <Text
                                    fontSize="14px"
                                    ml="5px"
                                    mr="30px"
                                    color="#FF6E36"
                                    bold
                                >
                                    Receive:{" "}
                                </Text>

                                {character?.status === 7 ? (
                                    <Text
                                        fontSize="18px"
                                        ml="5px"
                                        mr="30px"
                                        color="#03fc13"
                                        bold
                                    >
                                        {(formatter_4.format(
                                            parseInt(
                                                character?.stakeShares?._hex,
                                                16
                                            ) /
                                                10 ** 18
                                        ) ?? 0) + " PLS"}
                                    </Text>
                                ) : (
                                    <Text
                                        fontSize="18px"
                                        ml="5px"
                                        mr="30px"
                                        color="green"
                                        bold
                                    >
                                        {(formatter_4.format(dividend) ?? 0) +
                                            " PLS"}
                                    </Text>
                                )}
                            </Flex>
                            <>
                                {ex >= 1.5 && ex < 2 ? (
                                    <>
                                        <Flex
                                            alignItems="center"
                                            mb="20px"
                                            justifyContent="space-between"
                                        >
                                            <Text
                                                fontSize="14px"
                                                ml="5px"
                                                mr="30px"
                                                color="#FF6E36"
                                                bold
                                            >
                                                +Bonus:{" "}
                                            </Text>
                                            <Text
                                                fontSize="14px"
                                                ml="5px"
                                                mr="30px"
                                                bold
                                            >
                                                {formatter_4.format(
                                                    (((5 -
                                                        walletList?.indexOf(
                                                            Number(
                                                                character?.oneFiveX
                                                            )
                                                        )) /
                                                        5) *
                                                        Number(
                                                            bonusWallets?.[
                                                                Number(
                                                                    character?.oneFiveX
                                                                )
                                                            ]?.rawAmount
                                                        )) /
                                                        10 ** 18 /
                                                        Number(dayMiners?.[1])
                                                ) ?? 0}
                                            </Text>
                                        </Flex>
                                        <Flex
                                            alignItems="center"
                                            mb="20px"
                                            justifyContent="space-between"
                                        >
                                            <Text
                                                fontSize="14px"
                                                ml="5px"
                                                mr="30px"
                                                color="#FF6E36"
                                                bold
                                            >
                                                Total:{" "}
                                            </Text>
                                            <Text
                                                fontSize="18px"
                                                ml="5px"
                                                mr="30px"
                                                color="#03fc13"
                                                bold
                                            >
                                                {(formatter_4.format(
                                                    dividend +
                                                        (((5 -
                                                            walletList?.indexOf(
                                                                Number(
                                                                    character?.oneFiveX
                                                                )
                                                            )) /
                                                            5) *
                                                            Number(
                                                                bonusWallets?.[
                                                                    Number(
                                                                        character?.oneFiveX
                                                                    )
                                                                ]?.rawAmount
                                                            )) /
                                                            10 ** 18 /
                                                            Number(
                                                                dayMiners?.[1]
                                                            )
                                                ) ?? 0) + " PLS"}
                                            </Text>
                                        </Flex>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </>
                        </>
                    ) : character?.entryType === 0 && referCard === true ? (  
                        <>
                      <Flex alignItems='center' mb='5px' justifyContent='space-between'>
                      <Text fontSize="26px" ml='30px' mr='30px' mb='30px' color="white" bold>REDEEM REFERRALS TO MINER</Text>
                      </Flex>
                      <Flex alignItems='center' mb='20px' justifyContent='space-between'>
                      <Text fontSize="20px" ml='30px' mr='30px' color="#FF6E36" >Referals: </Text>
                      <Text fontSize="18px" ml='5px' mr='30px' >{formatter_4.format(Number(character?.refBonus ?? 0) / (10 ** 18))+" P2X"}</Text>
                      </Flex>
                      <Flex alignItems='center' mb='20px' justifyContent='space-between'>
                      <Text fontSize="20px" ml='30px' mr='30px' color="#FF6E36" >Price: </Text>
                      <Text fontSize="18px" ml='5px' mr='30px' >{"@ "+formatter_6.format(Number(character?.price ?? 0) / (10 ** 18))+' P2X/PLS'}</Text>
                      </Flex>          
                      <Flex alignItems='center' mb='0px' justifyContent='space-between'>
                      <Text fontSize="20px" ml='30px' mr='30px' color="#FF6E36" >Receive: </Text>
                      <Text fontSize="18px" ml='5px' mr='30px' >{formatter_4.format(Number(character?.refBonus ?? 0) / Number(character?.price ?? 0) )+" PLS"}</Text>
                      </Flex>   





                      <Paper mb='20px' bg="#1d201c" shadow="xs" p="xl">
                      <Flex mb='5px' flexDirection="column" justifyContent='space-between'>
                      <label
                              htmlFor="Enter-pls-Amount"
                              style={{
                                  color: "#FF6E36",
                                  marginBottom: "12px",
                                  marginLeft: "10px",
                              }}
                          >
                              Enter P2X Amount [Defaults @ Max]
                          </label>                                
                      <TextField
                            disabled = {parseFloat(character?.price) / (10 ** 18) <= 0 || parseFloat(character?.refBonus) / (10 ** 18) < 1}
                            
                            defaultValue = {formInput?.lobbyAmount}
                            value={parseFloat(formInput?.lobbyAmount) >= 1 ? Number(formInput?.lobbyAmount) : 0}
                            variant="filled"
                            type="number"
                            sx={{ input: { color: 'white', fontWeight: 500 }}}
                            InputLabelProps={{
                              
                              shrink: true,
                                style: { color: '#FF6E36' },
                            }}
                            className="inputField"
                            onChange={e => { 
                              var value = e.target.value;
                              if (value > refMax) value = refMax;
                              if (value < 1) value = 1;                                      
                              updateFormInput({ ...formInput, lobbyAmount: value });
                          }} 
                          //onPaste={(e) => {
                          //  e.preventDefault();
                          //  return false;
                          //}}  
                        />
                    </Flex>                            
                      <Flex flexDirection="column" mb='5x' justifyContent='space-between'>
                      <label
                              htmlFor="Enter-pls-Amount"
                              style={{
                                  color: "#FF6E36",
                                  marginBottom: "12px",
                                  marginLeft: "10px",
                              }}
                          >
                              Enter Wallet Address
                          </label>                                 
                          <TextField
                                      disabled = {parseFloat(character?.price) / (10 ** 18) <= 0 || parseFloat(character?.refBonus) / (10 ** 18) < 1}
                                      defaultValue = {formInput?.address}
                                      value = {formInput?.address}
                                      variant="filled"
                                      type="string"
                                      sx={{ input: { color: 'white', width: '400px' } }}
                                      InputLabelProps={{
                                          style: { color: '#ff4655' },
                                      }}
                                      className="inputField"
                                      onChange={e => { 
                                        var value = e.target.value;
                                        if (ethers.utils.isAddress(value) === false) value = '0x0';                                      
                                        updateFormInput({ ...formInput, address: value });
                                    }}                                            
                                  />       
                    </Flex>  
                    <Flex alignItems='left' mb='0px' justifyContent='space-between' flexDirection='column' >
                    <Text fontSize="14px" mt='10px' ml='10px' mr='30px' color="orange" bold>Using different address? Copy & Paste it above</Text>
                    <Text fontSize="14px" mt='20px' mb='0px' ml='10px' mr='30px' color="red" bold>Warning: Valid TruDefi user addresses only!</Text>
                    </Flex>     
                    </Paper>                         
                      </>
                  ) : levels === "Duelists" || levels === "Initiators" || levels === "BonusYielder" ? (
                        <>
                            <Flex
                                alignItems="center"
                                mb="20px"
                                justifyContent="space-between"
                            >
                                {character.entryType === 1 ||
                                character.entryType === 0 ? (
                                    <Text
                                        fontSize="24px"
                                        ml="5px"
                                        mr="30px"
                                        color="#fff"
                                        bold
                                    >
                                        ADD PLS TO LOBBY
                                    </Text>
                                ) : character.entryType === 4 ? (
                                    <Text
                                        fontSize="24px"
                                        ml="5px"
                                        mr="30px"
                                        color="#fff"
                                        bold
                                    >
                                        ADD TO YIELD BONUS{" "}
                                    </Text>
                                ) : (
                                    <Text
                                        fontSize="24px"
                                        ml="5px"
                                        mr="30px"
                                        color="#fff"
                                        bold
                                    >
                                        ADD YIELD TO LOBBY
                                    </Text>
                                )}
                            </Flex>
                            <Flex mb="20px" flexDirection="column">
                                <label
                                    htmlFor="Enter-pls-Amount"
                                    style={{
                                        color: "#FF6E36",
                                        marginBottom: "12px",
                                    }}
                                >
                                    Enter (PLS )Amount
                                </label>
                                <TextField
                                    id="Enter-pls-Amount"
                                    defaultValue={formInput.plsAmount}
                                    value={formInput.plsAmount}
                                    variant="filled"
                                    type="number"
                                    sx={{
                                        input: {
                                            color: "white",
                                        },
                                    }}
                                    className="inputField"
                                    onChange={(e) => {
                                        var value = e.target.value;
                                        if (value > 8000000000)
                                            value = 8000000000;
                                        if (value < 1) value = 1;
                                        updateFormInput({
                                            ...formInput,
                                            plsAmount: value,
                                        });
                                    }}
                                />
                            </Flex>
                            <Flex flexDirection="column" mb="20px">
                                <label
                                    htmlFor="Enter-refree-id"
                                    style={{
                                        color: "#FF6E36",
                                        marginBottom: "12px",
                                    }}
                                >
                                    Enter Referrer ID
                                </label>
                                <TextField
                                    disabled={_inDay}
                                    id="Enter-refree-id"
                                    defaultValue={Number(refID)}
                                    value={formInput.referrerID}
                                    variant="filled"
                                    type="number"
                                    sx={{
                                        input: {
                                            color: "white",
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: "#ff4655",
                                        },
                                    }}
                                    className="inputField"
                                    onChange={(e) => {
                                        var value = e.target.value;
                                        if (value < 1) value = 0;
                                        updateFormInput({
                                            ...formInput,
                                            referrerID: value,
                                        });
                                    }}
                                />
                            </Flex>
                        </>
                    ) : (
                        <></>
                    )}

                    <Flex alignItems="center" justifyContent="flex-end">
                        {character.entryType === 0 && referCard === true ? (
                            <Button
                                primary
                                className={`orange_btn btn_${levels}`}
                                disabled={entryTxIsLoaging || exitTxIsLoaging}
                                size="large"
                                onClick={() => {
                                    lobbyEntryWrite(), closeModal();
                                }}
                                color="green"
                                outline
                                rounded
                            >Redeem to Miner</Button>

                        ) : character.entryType === 4 ? (
                            <ReactiveButton
                                disabled={entryTxIsLoaging || exitTxIsLoaging}
                                idleText={
                                    <Flex alignItems="center">
                                        <Icon
                                            icon="clarity:logout-line"
                                            color="#f4516c"
                                            width="15"
                                            height="15"
                                        />
                                        <Text fontSize="14px" ml="3px" bold>
                                            Add to Bonus
                                        </Text>
                                    </Flex>
                                }
                                size="large"
                                onClick={() => {
                                    lobbyEntryWrite(), closeModal();
                                }}
                                color="green"
                                outline
                                rounded
                            />
                        ) : levels === "Initiators" ? (
                            <>
                                <Flex flexDirection="column" alignItems="left">
                                    <ReactiveButton
                                        mb="10px"
                                        disabled={
                                            entryTxIsLoaging ||
                                            exitTxIsLoaging ||
                                            character?._day === currentDay
                                        }
                                        idleText={
                                            <Flex
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                {/* <Icon
                                                    icon="clarity:logout-line"
                                                    color={
                                                        character?._day ===
                                                        currentDay
                                                            ? "#f4516c"
                                                            : "gray"
                                                    }
                                                    width="15"
                                                    height="15"
                                                /> */}
                                                <Text
                                                    fontSize="18px"
                                                    color={
                                                        character?._day ===
                                                        currentDay
                                                            ? "#504f52"
                                                            : ""
                                                    }
                                                    ml="3px"
                                                    bold
                                                >
                                                    Start Miners
                                                </Text>
                                            </Flex>
                                        }
                                        size="large"
                                        onClick={() => {
                                            lobbyExitWrite(), closeModal();
                                        }}
                                    />
                                    {character?._day === currentDay ? (
                                        <Text
                                            fontSize="16px"
                                            color="yellow"
                                            mt="10px"
                                            ml="3px"
                                            bold
                                        >
                                            You can deploy your miners on: Day{" "}
                                            {currentDay + 1}
                                        </Text>
                                    ) : (
                                        <Flex mt='15px' flexDirection="column" alignItems="center">
                                        <Text fontSize="16px" color="orange" >You can deploy up to 20 miners per Auction Card</Text>
                                        <Text fontSize="18px" color="white" >You can have a total of 500 active miners</Text>
                              
                                        </Flex>
                                    )}
                                </Flex>
                            </>
                        ) : (
                            <ReactiveButton
                                disabled={entryTxIsLoaging || exitTxIsLoaging}
                                idleText={
                                    <Flex
                                        alignItems="center"
                                        justifyContent="flex-end"
                                    >
                                        {/* <Icon
                                            icon="clarity:logout-line"
                                            color="#f4516c"
                                            width="15"
                                            height="15"
                                        /> */}
                                        <Text fontSize="14px" ml="3px" bold>
                                            Add to Lobby
                                        </Text>
                                    </Flex>
                                }
                                size="large"
                                onClick={() => {
                                    lobbyEntryWrite(), closeModal();
                                }}
                            />
                        )}
                    </Flex>
                </Flex>
            </ReactModal>

            <ReactModal
                        isOpen={isLinkModalOpen}
                        onRequestClose={() => closeLinkModal()}
                        style={customStyles}
                    >
                        <Flex flexDirection="column" className="accountModal">
                            <Flex
                                alignItems="center"
                                mb="20px"
                                justifyContent="space-between"
                            >

                                        <Text
                                            fontSize="28px"
                                            ml="5px"
                                            mr="30px"
                                            color="white"
                                            bold
                                        >
                                            Your Referral Link
                                        </Text>
                                        <div id="copie"><Text fontSize="24px" ml="5px" mr="1px" color="yellow" >Copied</Text></div>
                                        <div
                                                onClick={() => {
                                                  navigator.clipboard.writeText(
                                                    "http://testnet2.tru2x.io/lobby?referralID=" +
                                                    Number(character.entryId)
                                                  );
                                                    copyToClipBoard()                                                 
                                                }}
                                                className="cursor-pointer"
                                            >
                                                <Icon
                                                    icon="fluent:copy-24-filled"
                                                    color="rgba(199, 185, 178, 0.8)"
                                                    width="30"
                                                    height="30"
                                                />
                                            </div>

                            </Flex>


                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Text fontSize="24px" ml="5px" mr="1px" color="cyan" >
                                      {"http://testnet2.tru2x.io/lobby?referralID=" +
                                      Number(character.entryId)}
                                </Text> 


                            </Flex>
                        </Flex>
                    </ReactModal>

        </Flex>
    );
}
const Button = styled.button`
    border-radius: 8px;
    padding: 10px 18px;
    font-weight: 600;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    background-color: ${({ primary }) => (primary ? "#d9511c" : "#171615")};
    color: ${({ primary }) => (primary ? "#f1ede9" : "#d9511c")};
    border: ${({ secondary }) => secondary && "1px solid #d9511c"};
`;
export default Card;
